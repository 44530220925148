import React from "react";
import {
  Datagrid,
  EmailField,
  List,
  TextField,
  ReferenceField,
  ChipField,
} from "react-admin";
import { NONE } from "../../utils";
import ActivationButton from "../backofficeUsers/ActivationButton";
import CustomizedBooleanField from "../comman-components/CustomizedBooleanField";

export const UsersList = (props) => {
  return (
    <List {...props} title="Users" bulkActionButtons={false}>
      <Datagrid rowClick={NONE}>
        <TextField source="id" label="ID" />
        <EmailField source="email" label="Email" />
        <CustomizedBooleanField source="is_verified" label="Is Verified ?" />
        <CustomizedBooleanField source="is_active" label="Is Active ?" />
        <EmailField source="deactivated_by" label="Deactivated By" />
        <TextField source="profile_type" label="Profile Type" />
        <ActivationButton />
      </Datagrid>
    </List>
  );
};
