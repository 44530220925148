import React from "react";
import {
  ArrayField,
  Datagrid,
  FileField,
  Show,
  TextField
} from "react-admin";


export const TestExecutionShow = (props) => {
  return (
    <Show {...props} title={"Test Execution"}>
      <ArrayField source="test_executions">
        <Datagrid>
          <TextField source="id" label="Id" />
          <FileField
            source="matches_csv_path"
            label="File"
            title="Test Execution Result File"
            download={true}
          />
          <TextField source="created_on" label="Created On" />
          <TextField source="elapsed_time_secs" label="Elapsed time (secs)" />
        </Datagrid>
      </ArrayField>
    </Show>
  );
};
