import React from "react";
import {
  Edit,
  SelectArrayInput,
  SimpleForm,
  TextInput,
  SaveButton,
  Toolbar,
  NumberInput
} from "react-admin";


const CustimizeEditToolbar = (props) => (
  <Toolbar {...props}>
    <SaveButton />
  </Toolbar>
);

export const EditCurrencyRateRule = (props) => (
  <Edit title="Update Currency Rule" {...props}>
    <SimpleForm toolbar={<CustimizeEditToolbar />} redirect="list">
      <NumberInput source="increment" label="Increment" resettable required />
      <NumberInput source="rate_limit" label="Rate limit (%)" resettable required />
      <NumberInput source="max_transaction_limit_per_day" label="Maximum Transaction Limit" resettable required />
      <NumberInput source="quote_amount_precision" label="Quote Amount Precision" resettable required />
      <NumberInput source="precision" label="Precision" resettable required />
    </SimpleForm>
  </Edit>
);
