import React, { useState, useEffect } from "react";
import { TopToolbar, usePermissions } from "react-admin";
import { ROLES } from "../../utils";
import RateDecisionButton from "./RateDecisionButton";

export default function RateDecisionActions(props) {
  const permission = usePermissions();

  if (props && props.data && !props.data.requires_decisions) {
    return <></>;
  }
  if (
    permission &&
    permission.permissions &&
    permission.permissions.roles.includes(ROLES.fxManager)
  ) {
    return (
      <TopToolbar>
        <RateDecisionButton {...props} />
      </TopToolbar>
    );
  } else {
    return <></>;
  }
}
