import React from "react";
import {Create, SelectArrayInput, SimpleForm, TextInput,email} from "react-admin";


export const CreateSystemSetting = (props) => {
    return (
        <Create title="Create New Setting" {...props}>
            <SimpleForm redirect="list">
                <TextInput source="key" type="text" resettable/>
                <TextInput source="content" type="text" resettable/>
            </SimpleForm>
        </Create>
    );
};
