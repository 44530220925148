
import React from "react";
import {
  TextField,
  NumberField,
  Show,
  ArrayField,
  Datagrid,
  SimpleShowLayout,
  DateField,
  EmailField
} from "react-admin";
import { GridShowLayout, RaGrid } from "ra-compact-ui";
import CustomizedBooleanField from "../comman-components/CustomizedBooleanField";

export const FlowsShow = (props) => {
    return (
      <Show
          {...props}
          title={"Flows"}
      >
        <SimpleShowLayout>
            <GridShowLayout label="order">
                <RaGrid container direction="row" md={12} xs={12} justifyContent="space-between">
                  <RaGrid item md={3} xs={12}>
                    <DateField source="order_details.created_on" label="Order Created On" />
                  </RaGrid>
                  <RaGrid item md={3} xs={12}>
                    <TextField source="order_details.id" label="Order ID" />
                  </RaGrid>
                  <RaGrid item md={3} xs={12}>
                    <TextField source="order_details.order_type" label="Order Type" />
                  </RaGrid> 
                  <RaGrid item md={3} xs={12}>
                    <NumberField source="order_details.amount" label="Order Amount" />
                  </RaGrid>
                  <RaGrid item md={3} xs={12}>
                    <TextField source="order_details.base_currency" label="Order Base Currency" />
                  </RaGrid>
                  <RaGrid item md={3} xs={12}>
                    <TextField source="order_details.quote_currency" label="Order Quote Currency" />
                  </RaGrid>  
                  <RaGrid item md={3} xs={12}>
                    <EmailField source="order_details.email" label="User Email" />
                  </RaGrid>    
                </RaGrid>
            </GridShowLayout>

            <ArrayField
                source="order_related_flows"
                label={"Order Related Flows"}
              >
                <Datagrid>
                  <DateField source="created_on" label="Created On" />
                  <TextField source="agent_type" label="Agent Type" />
                  <TextField source="type" label="Transaction Type" />
                  <NumberField source="amount" label="Amount" />
                  <TextField source="currency_ticker" label="Currency" />
                  <NumberField source="fees" label="Fees" />
                  <CustomizedBooleanField source="claimed" label="Claimed ?" />
                </Datagrid>
              </ArrayField>
        </SimpleShowLayout>
          
      </Show>
    );
};
