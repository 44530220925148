import React from "react";

import { Typography, Card, CardContent, Grid, Box } from '@material-ui/core';


export const Volume = (props) => {
    const data = props.data;
    const classes = props.classes;
    
    return (
        <React.Fragment>
            <Typography variant="body1">Quick Totals</Typography>
            <Box mt={3} />
            <Grid container direction="row" md={12} xs={12}>
                <Grid item md={3} xs={12}>
                    <Card className={classes.card}>
                        <CardContent>
                            <Typography variant="body2">Total Current Supply</Typography>
                            <Typography variant="body2">{data.total_supply} ({data.supply_percentage}%)</Typography>
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item md={1} xs={12} />
                <Grid item md={3} xs={12}>
                    <Card className={classes.card}>
                        <CardContent>
                            <Typography variant="body2">Total Current Demand</Typography>
                          <Typography variant="body2">{data.total_demand} ({data.demand_percentage}%)</Typography>
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item md={1} xs={12} />
                <Grid item md={3} xs={12}>
                    <Card className={classes.card}>
                        <CardContent>
                            <Typography variant="body2">Total Volume</Typography>
                            <Typography variant="body2">{data.total_volume}</Typography>
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>

            <Box mt={3} />
          <Typography variant="body1">Current Platform Rate</Typography>
          <Box mt={3} />
          <Grid container direction="row" md={12} xs={12}>
                <Grid item md={3} xs={12}>
                    <Card className={classes.card}>
                        <CardContent>
                            <Typography variant="body2">Buy Rate</Typography>
                          <Typography variant="body2">{data.platform_rate ? data.platform_rate.buy_rate : 0}</Typography>
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item md={1} xs={12} />
                <Grid item md={3} xs={12}>
                    <Card className={classes.card}>
                        <CardContent>
                            <Typography variant="body2">Sell Rate</Typography>
                          <Typography variant="body2">{data.platform_rate ? data.platform_rate.sell_rate : 0}</Typography>
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item md={1} xs={12} />
          </Grid>
          <Box mt={3} />
        </React.Fragment>
    );
};
