import React, { useState } from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Grid from "@material-ui/core/Grid";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import {
  useDataProvider,
  useNotify,
  usePermissions,
  useRedirect,
} from "react-admin";

export default function RateDecisionButton(props) {
  const permissions = usePermissions();
  const [open, setOpen] = useState(false);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const [decision, setDecision] = useState("disapproved");
  const [uncompleteform, setUncompleteform] = useState(false);
  const dataProvider = useDataProvider();
  const redirect = useRedirect();
  const notify = useNotify();

  const handleDecisionChange = (event) => {
    setDecision(event.target.value);
    setUncompleteform(false);
  };
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const useStyles = makeStyles((theme) => ({
    paper: {
      marginTop: theme.spacing(8),
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
    },
    form: {
      width: "100%",
      marginTop: theme.spacing(3),
    },
    submit: {
      margin: theme.spacing(3, 0, 2),
    },
  }));
  const classes = useStyles();

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!e.target.comment.value && decision == "disapproved") {
      setUncompleteform(true);
      notify("Fill the Comment Section", "error");
      return;
    }

    const data = {
      decision: decision == "approved" ? true : false,
      last_decision_comment: e.target.comment.value,
      last_decision_by: permissions.permissions.email,
      rate_id: props.data.id,
    };

    const RateDecisionResult = await dataProvider.putRoute(
      "ratedecision",
      data
    );
    if (RateDecisionResult.data.success) {
      redirect("/rates");
      notify("Decision Done");
    } else {
      notify(RateDecisionResult.data.message, "error");
      redirect("/rates");
    }
  };

  return (
    <div>
      <Button variant="text" color="primary" onClick={handleClickOpen}>
        Submit Your Decision
      </Button>
      <Dialog
        fullScreen={fullScreen}
        open={open}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title">
          {"Decision Comment"}
        </DialogTitle>
        <DialogContent>
          <form className={classes.form} onSubmit={handleSubmit}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <FormControl component="fieldset">
                  <FormLabel component="legend">Decision</FormLabel>
                  <RadioGroup
                    aria-label="approvalstatus"
                    name="decision"
                    value={decision}
                    onChange={handleDecisionChange}
                  >
                    <FormControlLabel
                      value="approved"
                      control={<Radio />}
                      label="Approve"
                    />
                    <FormControlLabel
                      value="disapproved"
                      control={<Radio />}
                      label="Disapprove"
                    />
                  </RadioGroup>
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <TextField
                  label="Comment"
                  placeholder="Comment"
                  error={uncompleteform ? true : false}
                  name="comment"
                  fullWidth
                  rows="10"
                  rowsMax="10"
                  margin="normal"
                  multiline={true}
                  helperText={
                    uncompleteform
                      ? "Please provide a comment describing your decision"
                      : ""
                  }
                  InputLabelProps={{
                    shrink: true,
                  }}
                  variant="outlined"
                />
              </Grid>
            </Grid>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              className={classes.submit}
            >
              Submit
            </Button>
          </form>
        </DialogContent>
      </Dialog>
    </div>
  );
}
