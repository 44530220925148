import { stringify } from "query-string";
import { fetchUtils, DataProvider } from "ra-core";
import { useNotify } from "react-admin";
import { SERVER_URL } from "../env";
import decodeJwt from 'jwt-decode';
import {LOCAL_STORAGE_KEYS} from "../utils";

// @ts-ignore
// @ts-ignore
// @ts-ignore
/**
 * Maps react-admin queries to a json-server powered REST API
 *
 * @see https://github.com/typicode/json-server
 *
 * @example
 *
 * getList          => GET http://my.api.url/posts?_sort=title&_order=ASC&_start=0&_end=24
 * getOne           => GET http://my.api.url/posts/123
 * getManyReference => GET http://my.api.url/posts?author_id=345
 * getMany          => GET http://my.api.url/posts?id=123&id=456&id=789
 * create           => POST http://my.api.url/posts/123
 * update           => PUT http://my.api.url/posts/123
 * updateMany       => PUT http://my.api.url/posts/123, PUT http://my.api.url/posts/456, PUT http://my.api.url/posts/789
 * delete           => DELETE http://my.api.url/posts/123
 *
 * @example
 *
 * import * as React from "react";
 * import { Admin, Resource } from 'react-admin';
 * import jsonServerProvider from 'ra-data-json-server';
 *
 * import { PostList } from './posts';
 *
 * const App = () => (
 *     <Admin dataProvider={jsonServerProvider('http://jsonplaceholder.typicode.com')}>
 *         <Resource name="posts" list={PostList} />
 *     </Admin>
 * );
 *
 * export default App;
 */

const convertFileToBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = () => resolve(reader.result);
    reader.onerror = reject;

    reader.readAsDataURL(file.rawFile);
  });

const myHttpClient = async (
  url,
  options: any = {},
  throwOnError: boolean = true
) => {
  let response;
  if (!options.headers) {
    options.headers = new Headers({ Accept: "application/json" });
  }
  const token = localStorage.getItem("token");
  if (token) {
    options.headers.set("Authorization", `Bearer ${token}`);
  }
  response = await fetchUtils.fetchJson(url, options);

  if (response.json && response.json.success === false && throwOnError) {
    throw new Error(response.json.message);
  }

  return response;
};

export default (apiUrl, httpClient = myHttpClient): DataProvider => ({
  getList: (resource, params) => {
    const { page, perPage } = params.pagination;
    const { field, order } = params.sort;
    const query = {
      ...fetchUtils.flattenObject(params.filter),
      _sort: field,
      _order: order,
      _start: (page - 1) * perPage,
      _end: page * perPage,
    };

    if(resource == "dashboard_data")
      return new Promise((resolve, reject) => {
        return resolve({data: [], total: 0})
      })
    //FIXME
    // const realUrl = resource === 'backoffice-users' ? apiUrl : 'https://jsonplaceholder.typicode.com';
    const url = `${apiUrl}/${resource}?${stringify(query)}`;

    return httpClient(url).then(({ headers, json }) => {
      if (!headers.has("x-total-count")) {
        throw new Error(
          "The X-Total-Count header is missing in the HTTP Response. The jsonServer Data Provider expects responses for lists of resources to contain this header with the total number of results to build the pagination. If you are using CORS, did you declare X-Total-Count in the Access-Control-Expose-Headers header?"
        );
      }
      return {
        data: json,
        total: parseInt(headers.get("x-total-count").split("/").pop(), 10),
      };
    });
  },

  getOne: (resource, params) =>
    httpClient(`${apiUrl}/${resource}/${params.id}`).then(({ json }) => ({
      data: json,
    })),

  getMany: (resource, params) => {
    const query = {
      id: params.ids,
    };
    // to show something here ---->

    const url = `${apiUrl}/${resource}?${stringify(query)}`;
    return httpClient(url).then(({ json }) => ({ data: json }));
  },

  getManyReference: (resource, params) => {
    const { page, perPage } = params.pagination;
    const { field, order } = params.sort;
    const query = {
      ...fetchUtils.flattenObject(params.filter),
      [params.target]: params.id,
      _sort: field,
      _order: order,
      _start: (page - 1) * perPage,
      _end: page * perPage,
    };
    const url = `${apiUrl}/${resource}?${stringify(query)}`;

    return httpClient(url).then(({ headers, json }) => {
      if (!headers.has("x-total-count")) {
        throw new Error(
          "The X-Total-Count header is missing in the HTTP Response. The jsonServer Data Provider expects responses for lists of resources to contain this header with the total number of results to build the pagination. If you are using CORS, did you declare X-Total-Count in the Access-Control-Expose-Headers header?"
        );
      }
      return {
        data: json,
        total: parseInt(headers.get("x-total-count").split("/").pop(), 10),
      };
    });
  },

  update: (resource, params) => {
    const token = localStorage.getItem(LOCAL_STORAGE_KEYS.token);
    let decodedToken: any={};
    decodedToken = decodeJwt(token);
    params.data.userEmail = decodedToken.email;
    if (resource != "cms/team-members" && resource != "website-content")
      return httpClient(`${apiUrl}/${resource}/${params.id}`, {
        method: "PUT",
        body: JSON.stringify(params.data),
      }).then(({ json }) => ({ data: json }));

    if (params.data.pictures) {
      const pictureUploaded = convertFileToBase64(params.data.pictures);
      return pictureUploaded
        .then((res) =>
          myHttpClient(`${apiUrl}/${resource}/${params.id}`, {
            method: "PUT",
            body: JSON.stringify({ ...params.data, imageUploadedBase64: res }),
          })
        )
        .then(({ json }) => {
          return {
            data: { id: json.id },
          };
        });
    }
    return httpClient(`${apiUrl}/${resource}/${params.id}`, {
      method: "PUT",
      body: JSON.stringify(params.data),
    }).then(({ json }) => ({ data: json }));
  },
  // json-server doesn't handle filters on UPDATE route, so we fallback to calling UPDATE n times instead
  updateMany: (resource, params) =>
    Promise.all(
      params.ids.map((id) =>
        httpClient(`${apiUrl}/${resource}/${id}`, {
          method: "PUT",
          body: JSON.stringify(params.data),
        })
      )
    ).then((responses) => ({ data: responses.map(({ json }) => json.id) })),

  create: (resource, params) => {
    if (resource != "cms/team-members" && resource != "website-content") {
      return httpClient(`${apiUrl}/${resource}`, {
        method: "POST",
        body: JSON.stringify(params.data),
      }).then(({ json }) => {
        return {
          data: { ...params.data, id: json.id },
        };
      });
    } else {
      if (!params.data.file) {
        return httpClient(`${apiUrl}/${resource}`, {
          method: "POST",
          body: JSON.stringify(params.data),
        }).then(({ json }) => {
          return {
            data: { ...params.data, id: json.id },
          };
        });
      }
      const fileUploaded = convertFileToBase64(params.data.pictures);
      return fileUploaded
        .then((res) =>
          myHttpClient(`${SERVER_URL}/${resource}`, {
            method: "POST",
            body: JSON.stringify({ ...params.data, imageUploadedBase64: res }),
          })
        )
        .then(({ json }) => {
          return {
            data: { id: json.id },
          };
        });

     
    }
  },

  delete: (resource, params) =>
    httpClient(`${apiUrl}/${resource}/${params.id}`, {
      method: "DELETE",
    }).then(({ json }) => ({ data: json })),

  // json-server doesn't handle filters on DELETE route, so we fallback to calling DELETE n times instead
  deleteMany: (resource, params) =>
    Promise.all(
      params.ids.map((id) =>
        httpClient(`${apiUrl}/${resource}/${id}`, {
          method: "DELETE",
        })
      )
    ).then((responses) => ({ data: responses.map(({ json }) => json.id) })),

  putRoute: (route, data={}) => {
    return httpClient(
      `${apiUrl}/${route}`,
      {
        method: "PUT",
        body: JSON.stringify(data),
      },
      false
    ).then((response) => ({ data: response.json }));
  },
  postRoute: (route, data) => {
    return httpClient(
      `${apiUrl}/${route}`,
      {
        method: "POST",
        body: JSON.stringify(data),
      },
      false
    ).then((response) => ({ data: response.json }));
  },
  getRoute: (route, data) => {
    return httpClient(
      `${apiUrl}/${route}?${data}`,
      {
        method: "GET",
      },
      false
    ).then((response) => ({ data: response.json }));
  },
});
