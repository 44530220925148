import React from "react";
import { TopToolbar } from "react-admin";
import { OrganizationDocumentsNeeded } from "../../utils";
import OrganizationApproveButton from "./OrganizationApproveButton";
import OrganizationDisapproveButton from "./OrganizationDisapproveButton";

export default function VerifyOrganizationActions(props) {
  if (props.data && !props.data.needs_approval_decision) return <></>;
  if (!props.data || !"documents" in props.data) return <></>;
  if (props.data && !props.data.documents) return <></>;
  const documentsUploaded = new Set([
    ...props.data.documents.map((document) => document["document_type"]),
  ]);
  for (let document of OrganizationDocumentsNeeded) {
    if (!documentsUploaded.has(document)) {
      return <></>;
    }
  }

  let pendingDoc = props.data.documents.findIndex((document) => document.document_status == "Pending Approval")
    
  if(pendingDoc >= 0)
    return <></>

  let rejectedDoc = props.data.documents.findIndex((document) => document.document_status == "Rejected")

  if(rejectedDoc >= 0)
    return (
        <OrganizationDisapproveButton {...props} />
    );
    
  return (
    <>
      <OrganizationDisapproveButton {...props} />
      <OrganizationApproveButton {...props} />
    </>
  );
}
