import CircularProgress from "@material-ui/core/CircularProgress";
import React, { useEffect, useState } from "react";
import { SaveButton, Toolbar, usePermissions } from "react-admin";

import { ROLES } from "../../utils";
import { DialogConfirmation } from "./DialogConfirmation";

export const CustimizeEditToolbar = (props) => {
  const permission = usePermissions();
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    if (permission.loaded) {
      setLoading(false);
    }
  }, [permission.loaded]);

  if (loading) {
    return <CircularProgress />;
  }
  if (props.invalid) {
    return (
      <Toolbar {...props}>
        <SaveButton label="Save" />
      </Toolbar>
    );
  }
  if (permission.permissions.roles.includes(ROLES.fxManager)) {
    return (
      <Toolbar {...props}>
        <SaveButton
          label="Save"
          handleSubmitWithRedirect={() => {
            setShowConfirmation(true);
            return false;
          }}
        />
        {showConfirmation && (
          <DialogConfirmation
            showConfirmation={showConfirmation}
            setShowConfirmation={setShowConfirmation}
          />
        )}
      </Toolbar>
    );
  }
  return (
    <Toolbar {...props}>
      <SaveButton label="Save" />
    </Toolbar>
  );
};
