import PlayArrowIcon from "@material-ui/icons/PlayArrow";
import StopIcon from "@material-ui/icons/Stop";
import * as React from "react";
import { cloneElement, useEffect, useState } from "react";
import {
  Button,
  CreateButton,
  sanitizeListRestProps,
  TopToolbar,
  useDataProvider,
  useListContext,
  useRefresh,
  ExportButton,
} from "react-admin";
import GenerateMatchReport from "./GenerateMatchReport";
import { SentToDeliveryButton } from "./SentToDeliveryButton";

export default function Actions(props) {
  const { className, exporter, filters, maxResults, ...rest } = props;
  const {
    currentSort,
    resource,
    displayedFilters,
    filterValues,
    hasCreate,
    basePath,
    selectedIds,
    showFilter,
    total,
  } = useListContext();
  const dataProvider = useDataProvider();
  const refresh = useRefresh();
  const [systemStatus, setSystemStatus] = useState(true);
  const [reload, setReload] = useState(true);

  return (
    <TopToolbar className={className} {...sanitizeListRestProps(rest)}>
      {filters &&
        cloneElement(filters, {
          resource,
          showFilter,
          displayedFilters,
          filterValues,
          context: "button",
        })}      
      {/* <SentToDeliveryButton /> */}
      {/* <GenerateMatchReport /> */}
      
    </TopToolbar>
  );
}
