import React from "react";
import {
  ArrayField,
  Datagrid,
  DateField,
  EmailField,
  NumberField,
  Show,
  Tab,
  TabbedShowLayout,
  TextField
} from "react-admin";
import { Box } from '@material-ui/core';
import CustomizedBooleanField from "../comman-components/CustomizedBooleanField";
import CustomizedLink from "../comman-components/CustomizedLink";
import VerifyOrder from "./VerifyOrder";

const WithProps = ({ children, ...props }) => children(props);

export const OrderShow = (props) => {
  return (
    <Show {...props} title={"Order Details"} actions={<VerifyOrder />}>
      <WithProps>
        {({ record, ...props }) => (
          <TabbedShowLayout {...props}>
            <Tab label="Summary">
              <TextField label="Id" source="masked_id" />
              <EmailField label="Email" source="email" />
              <TextField source="normalized_type" label="Order Type" />
              <TextField source="amount" label="Amount" />
              <TextField source="base_currency" label="Base Currency" />
              <TextField source="quote_currency" label="Quote Currency" />
               <CustomizedBooleanField source="use_platform_rate" label="Using Platform Rate" addLabel/>
               <TextField source="tolerance" label="Tolerance" />
              <TextField source="rate" label="Rate" />
              <TextField source="approval_status" label="Approval Status" />
              {record['transaction_id_before_matching'] && <TextField source="transaction_id_before_matching" label="Transaction ID Before Matching" />}
              {record['transaction_id_post_matching'] && <TextField source="transaction_id_post_matching" label="Transaction ID Post Matching" />}
            </Tab>
            <Tab label="Flows">
              <ArrayField
                  source="flows"
                  label={"Order Related Flows"}
                >
                  <Datagrid>
                    <DateField source="created_on" label="Created On" />
                    <TextField source="agent_type" label="Agent Type" />
                    <TextField source="type" label="Transaction Type" />
                    <TextField source="system_transaction_id" label="System Transaction ID" />
                    <TextField source="partner_transaction_id" label="Partner Transaction ID" />
                    <NumberField source="amount" label="Amount" />
                    <TextField source="currency_ticker" label="Currency" />
                    <NumberField source="fees" label="Fees" />
                    <CustomizedBooleanField source="claimed" label="Claimed ?" />
                  </Datagrid>
                </ArrayField>
            </Tab>
            {record["transaction_date"] && (
              <Tab label="Source of Fund">
                {record["transaction_date"] && (
                  <TextField source="transaction_date" label="Transaction Date" />
                )}
                {record["fund_details"] && (
                  <TextField source="fund_details" label="Details" />
                )}
                {record["fund_beneficial_right"] && (
                  <TextField
                    source="fund_beneficial_right"
                    label="Beneficial Right Owner"
                  />
                )}
                {record["fund_status"] && (
                  <TextField source="fund_status" label="Source of Fund Status" />
                )}
                {record["fund_rejection_reason"] && (
                  <TextField source="fund_rejection_reason" label="Rejection Reason" />
                )}
                {record["fund_decision_by"] && (
                  <EmailField source="fund_decision_by" label="Decision By" />
                )}
                {record["fund_signature"] && (
                  <TextField source="fund_signature" label="Signature" />
                )}

                {record["fund_document"] && (
                    <CustomizedLink source="fund_document" placeholder="Download Source of Fund Document" />
                )}

                <Box mt={2} />
              </Tab>
            )}
          </TabbedShowLayout>
        )}
      </WithProps>
    </Show>
  );
};
