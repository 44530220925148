import React from "react";
import {
  ArrayField,
  Datagrid,
  DateField,
  EmailField,
  Show,
  Tab,
  TabbedShowLayout,
  TextField,
  TopToolbar,
  UrlField,
} from "react-admin";
import CustomizedBooleanField from "./CustomizedBooleanField";
import "./organizationcomponents_css/input.css";
import { OrganizationDocumentTab } from "./OrganizationDocumentTab";
import VerifyOrganizationActions from "./VerifyOrganizationActions";
import { GridShowLayout, RaGrid } from "ra-compact-ui";
import CustomizedLink from "../comman-components/CustomizedLink";
import GenerateProfileReport from "../comman-components/GenerateProfileReport";
import CustomizedDocumentLink from "../comman-components/CustomizedDocumentLink";

const OrganizationActions = (props) => {
  return (
    <TopToolbar>
        <VerifyOrganizationActions {...props} />
        {props.data && (
          <GenerateProfileReport
            link={`generate-organization-pdf/${props.data.id}`}
          />
        )}
    </TopToolbar>
  )
}

export const OrganizationUsersShow = (props) => {
  return (
    <Show
      {...props}
      title={"Organization Profile"}
      actions={<OrganizationActions />}
    >
      <TabbedShowLayout>
        <Tab label="Summary">
          <GridShowLayout>
              <RaGrid container direction="row" md={12} xs={12} justifyContent="space-between">
                <RaGrid item md={3} xs={12}>
                  <TextField source="masked_id" label="Id" />
                </RaGrid>
                <RaGrid item md={3} xs={12}>
                  <EmailField source="user_email" label="Email" />
                </RaGrid>
                <RaGrid item md={3} xs={12}>
                  <TextField source="created_on" label="Date of Registration" />
                </RaGrid>
                <RaGrid item md={3} xs={12}>
                  <TextField source="telephone" label="Phone Number" /> 
                </RaGrid>
              </RaGrid>
            </GridShowLayout>

            <GridShowLayout>
              <RaGrid container direction="row" md={12} xs={12} justifyContent="space-between">
                <RaGrid item md={3} xs={12}>
                  <TextField source="name" label="Organization Name" />
                </RaGrid>
                <RaGrid item md={3} xs={12}>
                  <TextField source="date_of_registration" label="Date of Incorporation" />
                </RaGrid>
                <RaGrid item md={3} xs={12}>
                  <TextField source="country_of_registration" label="Place of Incorporation" />
                </RaGrid>
                <RaGrid item md={3} xs={12}>
                  <TextField source="legal_form" label="Legal Form" />
                </RaGrid>
              </RaGrid>
            </GridShowLayout>

            <GridShowLayout>
              <RaGrid container direction="row" md={12} xs={12} justifyContent="space-between">
                <RaGrid item md={3} xs={12}>
                  <TextField source="occupations" label="Occupation Code" />  
                </RaGrid>
                <RaGrid item md={3} xs={12}>
                  <TextField source="number_of_employees" label="Number of Employees" />  
                </RaGrid>
                <RaGrid item md={3} xs={12}>
                  <TextField source="interaction_countries" label="Countries of Business Interactions" />
                </RaGrid>
                <RaGrid item md={3} xs={12}>
                  <TextField source="detailed_activity" label="Details of Activity" style={{wordBreak: 'break-word'}}/>
                </RaGrid>
              </RaGrid>
            </GridShowLayout>

            <GridShowLayout>
              <RaGrid container direction="row" md={12} xs={12} justifyContent="space-between">
                <RaGrid item md={3} xs={12}>
                  <TextField source="expected_annual_volume_IN_OUT_in_USD" label="Expected Annual Volume (IN-OUT) in USD" />
                </RaGrid>
                <RaGrid item md={3} xs={12}>
                  <TextField source="annual_sales_in_USD" label="Annual Sales" />
                </RaGrid>
                <RaGrid item md={3} xs={12}>
                  <TextField source="financial_situation_additional_information" label="Financial Situation Additional information" style={{wordBreak: 'break-word'}}/>
                </RaGrid>
                <RaGrid item md={3} xs={12}>
                  <TextField source="ownership" label="Beneficial Right Owner" />
                </RaGrid>
              </RaGrid>
            </GridShowLayout>

            <GridShowLayout>
              <RaGrid container direction="row" md={12} xs={12} justifyContent="space-between">
                <RaGrid item md={3} xs={12}>
                  <TextField source="head_office_country" label="Head office Country" />
                </RaGrid>
                <RaGrid item md={3} xs={12}>
                  <TextField source="head_office_address" label="Head office Address" />
                </RaGrid>
                <RaGrid item md={3} xs={12}>
                  <EmailField source="email" label="Alternative Email" />
                </RaGrid>
                <RaGrid item md={3} xs={12}>
                  <UrlField source="website" label="Website" />
                </RaGrid>
              </RaGrid>
            </GridShowLayout>

            <GridShowLayout>
              <RaGrid container direction="row" md={12} xs={12} justifyContent="space-between">
                <RaGrid item md={3} xs={12}>
                  <CustomizedBooleanField source="is_approved" label="Approved ?" addLabel />
                </RaGrid>
                <RaGrid item md={3} xs={12}>
                  <CustomizedBooleanField source="is_user_activated" label="Activated Account" addLabel />
                </RaGrid>
              </RaGrid>
            </GridShowLayout>
        </Tab>

        <Tab label="Owners" {...props}>
          <ArrayField source="owners_individual">
            <Datagrid>
              <TextField source="first_name" label="First Name" />
              <TextField source="last_name" label="Last Name" />
              <TextField source="father_name" label="Father Name" />
              <TextField source="nationality" label="Nationality" />
              <TextField source="residence" label="Residence" />
              <TextField source="percentage_of_ownership" label="Percentage of Ownership(%)" />
              <TextField source="document_type" label="Identification Type" />
              <CustomizedDocumentLink source="document_path" label="Identification Document" placeholder="download" />
              <TextField source="document_number" label="Identification Number" />
              <DateField source="document_expiry_date" label="Identification Expiry Date" />
            </Datagrid>
          </ArrayField>
          <ArrayField source="owners_entity">
            <Datagrid>
              <TextField source="name" label="Entity Name" />
              <TextField source="legal_form" label="Legal Form" />
              <TextField source="shareholders_names" label="Shareholders" />
              <TextField source="legal_representatives_names" label="Legal Representatives" />
              <TextField source="percentage_of_ownership" label="Percentage of Ownership(%)" />
            </Datagrid>
          </ArrayField>
        </Tab>

        <Tab label="Legal Representatives" {...props}>
          <ArrayField source="signatories">
            <Datagrid>
              <TextField source="first_name" label="First Name" />
              <TextField source="last_name" label="Last Name" />
              <TextField source="father_name" label="Father Name" />
              <TextField source="nationality" label="Nationality" />
              <TextField source="residence" label="Residence" />
              <CustomizedBooleanField source="is_legal" label="Is Legal ?" addLabel />
              <CustomizedBooleanField source="is_main_contact" label="Is Main Contact ?" addLabel />
              <TextField source="document_type" label="Identification Type" />
              <CustomizedDocumentLink source="document_path" label="Identification Document" placeholder="download" />
              <TextField source="document_number" label="Identification Number" />
              <DateField source="document_expiry_date" label="Identification Expiry Date" />
              <CustomizedLink source="delegation_document_path.Delegation" label="Delegation Document" placeholder="download" />
            </Datagrid>
          </ArrayField>
        </Tab>

        <Tab label="Documents" {...props}>
          <OrganizationDocumentTab {...props} />
        </Tab>

        <Tab label="KYC/KYB Cases" {...props}>
          <ArrayField source="kyc_cases" label={"KYC/KYB Cases"}>
            <Datagrid>
              <TextField source="externalid" label="Case Id" />
              <TextField source="type" label="Type" />
              <TextField source="name" label="Name" />
              <TextField source="matches" label="Matches" />
              <TextField source="provider" label="Provider" />
              <CustomizedLink source="link" label="Link" placeholder="External Case Link" />
            </Datagrid>
          </ArrayField>
        </Tab>

        <Tab label="Verification" {...props}>
          <ArrayField
            source="verification_decision_record"
            label={"Decision Record"}
          >
            <Datagrid>
              <TextField source="decision_date" label="Decision Date" />
              <EmailField source="email" label="Decision By" />
              <TextField source="fields_status" label="Rejected Fields" />
              <TextField source="decision_comment" />
              <TextField source="status" label="Status" />
            </Datagrid>
          </ArrayField>
        </Tab>

        <Tab label="Order Delegates" {...props}>
          <ArrayField source="organization_delegates">
            <Datagrid>
              <TextField source="first_name" label="First Name" />
              <TextField source="last_name" label="Last Name" />
              <UrlField source="identification_document_path" label="Identification Document" />
              <TextField source="identification_document_number" label="Identification Number" />
              <TextField source="identification_document_expiry_date" label="Identification Expiry Date" />
              <UrlField source="delegation_document_path" label="Delegation Document" />
            </Datagrid>
          </ArrayField>
        </Tab>
      </TabbedShowLayout>
    </Show>
  );
};
