import React from "react";
import { BooleanInput, Filter, TextInput } from "react-admin";
import CurrencySelector from "../ratingSettings/CurrencySelector";

function MatchListFilter(props) {
  return (
    <Filter {...props}>
      <CurrencySelector source={"baseCurrency"} />
      <CurrencySelector source={"quoteCurrency"} />
      <TextInput
        source="amount_limit"
        label="Amount Limit"
        defaultValue="2000"
      />
    </Filter>
  );
}

export default MatchListFilter;
