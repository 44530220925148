import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Grid from "@material-ui/core/Grid";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import React, { useEffect, useState } from "react";
import {
  useDataProvider,
  useNotify,
  usePermissions,
  useRedirect,
  useRefresh,
} from "react-admin";
import InputLabel from "@material-ui/core/InputLabel";
import FormHelperText from "@material-ui/core/FormHelperText";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import NativeSelect from "@material-ui/core/NativeSelect";
import { toBase64 } from "../../utils";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import IconButton from "@material-ui/core/IconButton";
import DeleteIcon from '@material-ui/icons/Delete';

const useStyles = makeStyles((theme) => ({
  paper: {
    minWidth: "40%",
  },
  form: {
    width: "100%",
    marginTop: theme.spacing(3),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

export default function OrganizationDisapproveButton(props) {
  const classes = useStyles();
  const permissions = usePermissions();
  const [organizationFields, setOrganizationFields] = useState([]);
  const emptyField = { field: "", status: "Rejected", note: "" };
  const [rejectedFields, setRejectedFields] = useState([emptyField]);
  const [open, setOpen] = useState(false);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const [submitted, setSubmitted] = useState(false);
  const dataProvider = useDataProvider();
  const redirect = useRedirect();
  const notify = useNotify();
  const refresh = useRefresh();

  useEffect(() => {
    getOrganizationFields();
  }, []);

  const getOrganizationFields = async () => {
    const profileFields = await dataProvider.getRoute(
      "/options/organization-fields"
    );
    setOrganizationFields(profileFields.data.fields);
  };

  const handleChange = (e, index) => {
    var tempState = [...rejectedFields];
    var tempElement = { ...tempState[index] };
    tempElement[e.target.name] = e.target.value;
    tempState[index] = tempElement;
    setRejectedFields(tempState);
  };

  const handleDeleteRejectedField = (index)=>{
    if(index == 0) return
    var tempState = [...rejectedFields];
    tempState.splice(index,1);
    setRejectedFields(tempState);
  }

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    // setSubmitted(true);

    const data = {
      decision: false,
      profile_id: props.data.id,
      decision_by: permissions.permissions.email,
      comment: e.target.comment.value,
      // risk_score: e.target.risk_score.value,
      profile_type: "Organization",
      fields_rejected: rejectedFields,
      // decision_file:
      //   e.target?.decision_file?.files[0] &&
      //   (await toBase64(e.target.decision_file.files[0])),
    };
    const verificationResult = await dataProvider.postRoute(
      "verify_profile",
      data
    );
    if (verificationResult.data.success) {
      notify("Verification Done");
      redirect("/organizations");
      refresh();
    } else {
      notify(verificationResult.data.message, "error");
      refresh();
      setSubmitted(false);
    }
    setOpen(false);
  };

  return (
    <div>
      <Button variant="text" color="primary" onClick={handleClickOpen}>
        Disapprove
      </Button>
      <Dialog
        fullScreen={fullScreen}
        open={open}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
        classes={{ paper: classes.paper }}
      >
        <DialogTitle id="responsive-dialog-title">
          {"Disapprove Form"}
        </DialogTitle>
        <DialogContent>
          <form onSubmit={handleSubmit}>
            <Grid container spacing={2} alignItems="center" justify="center">
              <Grid item xs={12}>
                {organizationFields &&
                  rejectedFields.map((field, index) => (
                    <Grid container spacing={2} direction="row">
                      <Grid item xs={5}>
                        <FormControl required>
                          <InputLabel htmlFor="fields-native-helper">
                            Field
                          </InputLabel>
                          <NativeSelect
                            value={rejectedFields[index].field}
                            onChange={(e) => handleChange(e, index)}
                            inputProps={{
                              name: "field",
                              id: "fields-native-helper",
                            }}
                          >
                            <option aria-label="None" value="" />
                            {organizationFields.map((field) => (
                              <option value={field.value}>{field.display}</option>
                            ))}
                          </NativeSelect>
                          <FormHelperText>Select Rejected Field</FormHelperText>
                        </FormControl>
                      </Grid>
                      <Grid item xs={5}>
                        <TextField
                          fullWidth
                          onChange={(e) => handleChange(e, index)}
                          value={rejectedFields[index].note}
                          label="Field Note"
                          name="note"
                          required
                        />
                      </Grid>
                      <Grid item xs={2}>
                        <IconButton
                          onClick={() =>handleDeleteRejectedField(index)}
                        >
                          <DeleteIcon color="error"/>
                        </IconButton>
                      </Grid>
                    </Grid>
                  ))}
              </Grid>
              <Grid item>
                <IconButton
                  onClick={() =>
                    setRejectedFields([...rejectedFields, emptyField])
                  }
                >
                  <AddCircleOutlineIcon />
                </IconButton>
              </Grid>
              <Grid item xs={12}>
                {/* <TextField
                  type="number"
                  variant="outlined"
                  label="Risk Score"
                  name="risk_score"
                  fullWidth
                /> */}
                {/* <input
                  type="file"
                  name="decision_file"
                  accept="application/pdf"
                  style={{ marginTop: "1rem" }}
                /> */}
                <TextField
                  required
                  label="Overall Comment on the profile"
                  placeholder="Comment"
                  name="comment"
                  fullWidth
                  rows="10"
                  rowsMax="10"
                  margin="normal"
                  multiline={true}
                  inputProps={{ maxLength: 500 }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  variant="outlined"
                />
              </Grid>
            </Grid>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              className={classes.submit}
              disabled={submitted}
            >
              Submit
            </Button>
          </form>
        </DialogContent>
      </Dialog>
    </div>
  );
}
