import React from "react";
import { OrganizationDocumentsNeeded, removeFromArray } from "../../utils";
import OrganizationDocumentField from "./OrganizationDocumentField";

export const OrganizationDocumentTab = (props) => {
  const documents = props?.record?.documents;
  var documentsMissed = OrganizationDocumentsNeeded;

  if (documents)
    return (
      <>
        {documents.map((value) => {
          documentsMissed = removeFromArray(
            documentsMissed,
            value.document_type
          );
          return (
            <>
              <OrganizationDocumentField
                source={value.document_path}
                label={"Document Title"}
                title={value.document_type}
                documentDetails={value}
                addLabel
                {...props}
              />
            </>
          );
        })}
        {documentsMissed.map((value) => {
          return <div style={{ color: "red" }}>{value} Missed !</div>;
        })}
      </>
    );
  return <></>;
};
