import React, { useState } from "react";
import {
  useDataProvider,
  useNotify,
  usePermissions,
  useRefresh,
  Confirm,
  useRedirect,
} from "react-admin";
import TextField from "@material-ui/core/TextField";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Grid from "@material-ui/core/Grid";
import { makeStyles, useTheme } from "@material-ui/core/styles";

import { getUserEmail, http_put, PROFILETYPES, toBase64 } from "../../utils";

const useStyles = makeStyles((theme) => ({
  paper: {
    minWidth: "40%",
  },
  form: {
    width: "100%",
    marginTop: theme.spacing(3),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

const ProfileApproveButton = (props) => {
  const redirect = useRedirect();
  const theme = useTheme();
  const profileType = props?.profileType;
  const classes = useStyles();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const notify = useNotify();
  const dataProvider = useDataProvider();
  const permissions = usePermissions();
  const refresh = useRefresh();
  const [submitted, setSubmitted] = useState(false);
  const [openDialogArea, setOpenDialogArea] = useState(false);
  const openDialog = () => setOpenDialogArea(true);
  const closeDialog = () => setOpenDialogArea(false);

  const approveAccount = async (e) => {
    e.preventDefault();
    setSubmitted(true);
    const data = {
      decision: true,
      profile_id: props.data.id,
      decision_by: permissions.permissions.email,
      comment: e.target.comment.value,
      risk_score: e.target.risk_score.value,
      profile_type: profileType,
      decision_file:
        e.target?.decision_file?.files[0] &&
        (await toBase64(e.target.decision_file.files[0])),
    };

    const updateResponse = await dataProvider.postRoute("verify_profile", data);

    if (updateResponse.data.success) {
      notify("Account has been Approved");
      redirect(profileType == PROFILETYPES.Individual ? '/individuals' : '/enterprises')
      refresh();
    } else {
      notify(updateResponse.data.message, "error");
      refresh();
      setSubmitted(false);
    }
    setOpenDialogArea(false);
  };

  if(props.data.documents){
    let documents = props.data.documents;
    let rejectedDoc = documents.findIndex((document) => document.document_status == "Rejected")
    if(rejectedDoc > 0)
      return <></>
  }

  return (
    <>
      <Button variant="text" onClick={openDialog} color="primary">
        Approve
      </Button>
      <Dialog
        fullScreen={fullScreen}
        open={openDialogArea}
        onClose={closeDialog}
        aria-labelledby="responsive-dialog-title"
        classes={{ paper: classes.paper }}
      >
        <DialogTitle id="responsive-dialog-title">{"Approve Form"}</DialogTitle>
        <DialogContent>
          <form className={classes.form} onSubmit={approveAccount}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField
                  type="number"
                  variant="outlined"
                  label="Risk Score"
                  name="risk_score"
                  fullWidth
                />
                <input
                  type="file"
                  accept="application/pdf"
                  name="decision_file"
                  style={{ marginTop: "1rem" }}
                />
                <TextField
                  required
                  label="Comment"
                  placeholder="Comment"
                  name="comment"
                  fullWidth
                  rows="10"
                  rowsMax="10"
                  margin="normal"
                  multiline={true}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  variant="outlined"
                />
              </Grid>
            </Grid>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              className={classes.submit}
              disabled={submitted}
            >
              Submit
            </Button>
          </form>
        </DialogContent>
      </Dialog>
    </>
  );
};
export default ProfileApproveButton;
