import Grid from "@material-ui/core/Grid";
import CancelIcon from "@material-ui/icons/Cancel";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import React, { useState } from "react";
import { Button, useDataProvider, useNotify, useRefresh } from "react-admin";
import { Documents, DocumentStatus } from "../../utils";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import MUIButton from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";

const ProfileDocumentField = ({ source, title, label, documentDetails,profileType }) => {
  const dataProvider = useDataProvider();
  const notify = useNotify();
  const refresh = useRefresh();
  const [openRejectionDialog, setOpenRejectionDialog] = useState(false)
  const [rejectionReason, setRejectionReason] = useState(null)

  const dispalyStatusIcon = () => {
    if (documentDetails["document_status"] == DocumentStatus.Approved) {
      return <CheckCircleIcon fontSize="inherit" style={{ color: "green" }} />;
    }
    if (documentDetails["document_status"] == DocumentStatus.Rejected) {
      return <CancelIcon fontSize="inherit" style={{ color: "red" }} />;
    }
  };

  const updateDocumentStatus = async (status, profileId, documentType, reason) => {
    try {
      let res = await dataProvider.putRoute(
        "update-profile-document-status/" + profileId,
        {
          status: status,
          document: documentType,
          profile_type:profileType,
          reason:reason
        }
      );
      if (!res.data.success) {
        notify(res.data.message, "error");
      } else {
        notify("Status updated");
      }
    } catch (err) {
      notify("Failed to updated status", "error");
      console.error(err);
    }
    refresh();
  };

  const handleDownload = (e) => {
    e.preventDefault();
    var urls = Object.values(source)
    
    var link = document.createElement('a');

    link.setAttribute('download', null);
    link.style.display = 'none';
  
    document.body.appendChild(link);
  
    for (var i = 0; i < urls.length; i++) {
      link.setAttribute('href', urls[i]);
      link.click();
    }
  
    document.body.removeChild(link);    
  }

  const submitRejectionReason = (e) => {
    e.preventDefault();
    updateDocumentStatus(
      DocumentStatus.Rejected,
      documentDetails["profile_id"],
      documentDetails["document_type"],
      rejectionReason
    )
  }

  if (documentDetails)
    return (
      <>
        {
          (documentDetails["document_type"] == Documents.NationalID || documentDetails["document_type"] == Documents.Passport) &&
            <Grid 
              container direction="row" 
              justify="flex-start"
              alignItems="center"
              spacing={7}
            >
              <Grid item>
                <Typography variant="caption" style={{color: "rgba(0, 0, 0, 0.54)"}}>Identification Number </Typography>
                <Typography variant="body2" style={{ marginTop: ".5rem"}}>{documentDetails['document_number']}</Typography>
              </Grid>
              {documentDetails['document_expiry_date'] &&
                <Grid item>
                  <Typography variant="caption" style={{color: "rgba(0, 0, 0, 0.54)"}}>Identification Expiry Date </Typography>
                  <Typography variant="body2" style={{ marginTop: ".5rem"}}>{documentDetails['document_expiry_date']}</Typography>
                </Grid>              
              } 
            </Grid>   
        }
        <Grid
          container
          direction="row"
          justify="flex-start"
          alignItems="center"
          spacing={1}
        >
          <Grid item>
              <Button
                color="primary"
                label={title}
                variant="text"
                onClick={handleDownload}
                style={{textDecoration: "underline", fontWeight: "bold"}}
              />
          </Grid>
          {source &&
            documentDetails["document_status"] !=
              DocumentStatus.ToBeUploaded && (
              <>
                <Grid item>{dispalyStatusIcon()}</Grid>
                <Grid item>
                  {documentDetails["document_status"] !=
                    DocumentStatus.Approved && (
                    <Button
                      label="accept"
                      onClick={() =>
                        updateDocumentStatus(
                          DocumentStatus.Approved,
                          documentDetails["profile_id"],
                          documentDetails["document_type"],
                          null
                        )
                      }
                    />
                  )}
                </Grid>
                <Grid item>
                  {documentDetails["document_status"] !=
                    DocumentStatus.Rejected && (
                      <React.Fragment>
                        <Button
                          label="reject"
                          onClick={() => setOpenRejectionDialog(true)}
                        />
                        <Dialog
                          open={openRejectionDialog}
                          onClose={() => setOpenRejectionDialog(false)}
                        >
                          <DialogTitle id="alert-dialog-title">Rejection Reason</DialogTitle>
                          <form onSubmit={submitRejectionReason}>
                            <DialogContent>
                              <TextField
                                fullWidth
                                onChange={(e) => setRejectionReason(e.target.value)}
                                value={rejectionReason}
                                label="Rejection Reason"
                                name="rejection_reason"
                                required
                              />

                            </DialogContent> 
                            <DialogActions>
                              <MUIButton
                                onClick={() => setOpenRejectionDialog(false)}
                                color="primary"
                              >
                                Cancel
                              </MUIButton>
                              <MUIButton
                                type="submit"
                                color="primary"
                                autoFocus
                              >
                                Submit
                              </MUIButton>
                            </DialogActions>
                          </form>
                        </Dialog>
                      </React.Fragment>
                  )}
                </Grid>
              </>
            )}
        </Grid>
      </>
    );
  return <></>;
};

export default ProfileDocumentField;
