import React from "react";
import {
  Datagrid,
  List,
  TextField,
  NumberField,
  ShowButton,
  DateField,
  Filter,
  TextInput,
  ChipField,
  BooleanInput,
  SelectInput
} from "react-admin";
import { NONE } from "../../utils";
import CustomizedBooleanField from "../comman-components/CustomizedBooleanField";
import CurrencySelector from "../ratingSettings/CurrencySelector";

const transaction_types = [
  {id: "Deposit", name: "Deposit"},
  {id: "Withdrawal", name: "Withdrawal"}
]

const customer_types = [
  {id: "Organization", name: "Organization"},
  {id: "Business Enterprise", name: "Business Enterprise"},
  {id: "Individual", name: "Individual"}
]

const agent_types = [
  {id: "BOB", name: "BOB"}
]

const FlowsFilters = (props) => {
  return (
    <Filter {...props}>
      <CurrencySelector label="Currency" source="currency" />
      <SelectInput label="Agent Type" source="agent_type" choices={agent_types} />
      <TextInput label="Order ID" source="order_id" />
      <BooleanInput label="Claimed" source="claimed" defaultValue={true}/>
      <TextInput label="Account Number" source="account_number" />
      <SelectInput label="Customer Type" source="profile_type" choices={customer_types} />
      <SelectInput label="Transaction Type" source="type" choices={transaction_types} />
    </Filter>
  )
}

export const FlowsList = (props) => {
  return (
    <List {...props} title="Flows" 
      bulkActionButtons={false}
      filters={<FlowsFilters />}
    >
      <Datagrid rowClick={NONE}>
        <DateField source="created_on" label="Created On" />
        <TextField source="agent_type" label="Agent Type" />
        <TextField source="partner_transaction_id" label="Partner Transaction ID" />
        <TextField source="system_transaction_id" label="System Transaction ID" />
        <TextField source="type" label="Transaction Type" />
        <NumberField source="order_id" label="Order ID" />
        <TextField source="profile_type" label="Customer Type" />
        <NumberField source="account_number" label="Account Number" />
        <TextField source="customer_name" label="Name" />
        <TextField source="currency_ticker" label="Currency" />
        <NumberField source="amount" label="Amount" />
        <NumberField source="fees" label="Fees" />
        <CustomizedBooleanField source="claimed" label="Claimed ?" />
        <ShowButton />
      </Datagrid>
    </List>
  );
};
