import Button from "@material-ui/core/Button";
import React from "react";
import {
  useDataProvider,
  useNotify,
  usePermissions,
  useRefresh,
} from "react-admin";

const ActivationButton = ({record}) => {
  var id;
  "users_id" in record ? (id = record["users_id"]) : (id = record.id);
  const permissions = usePermissions();
  const notify = useNotify();
  const dataProvider = useDataProvider();
  const refresh = useRefresh();
  const toggleUserActivationStatus = async () => {
    try {
      await dataProvider.putRoute("changeActivationStatus/" + id);
      notify("Status updated");
    } catch (err) {
      notify("Failed to updated status", "error");
      console.error(err);
    }
    refresh();
  };

  return (
    <Button
      variant="text"
      onClick={toggleUserActivationStatus}
      color="primary"
      disabled={
        permissions.loaded && permissions.permissions.email === record.email
      }
    >
      {record.is_active ? "Deactivate" : "Activate"}
    </Button>
  );
};
export default ActivationButton;
