import React from "react";

import { Typography, Card, CardContent, Grid, Box } from '@material-ui/core';


export const Orders = (props) => {
    const data = props.data;
    const classes = props.classes;

    return (
        <React.Fragment>
            <Typography variant="body1">Quick Totals</Typography>
            <Box mt={3} />
            <Grid container direction="row" md={12} xs={12}>
                <Grid item md={3} xs={12}>
                    <Card className={classes.card}>
                        <CardContent>
                            <Typography variant="body2">Matched Orders</Typography>
                            <Typography variant="body2">{data.total_matched}</Typography>
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item md={1} xs={12} />
                <Grid item md={3} xs={12}>
                    <Card className={classes.card}>
                        <CardContent>
                            <Typography variant="body2">Completed Orders</Typography>
                            <Typography variant="body2">{data.total_completed}</Typography>
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item md={1} xs={12} />
                <Grid item md={3} xs={12}>
                    <Card className={classes.card}>
                        <CardContent>
                            <Typography variant="body2">Canceled Orders</Typography>
                            <Typography variant="body2">{data.total_canceled}</Typography>
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item md={1} xs={12} />
                <Grid item md={3} xs={12}>
                    <Card className={classes.card}>
                        <CardContent>
                            <Typography variant="body2">Pending Orders</Typography>
                            <Typography variant="body2">{data.total_pending}</Typography>
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item md={1} xs={12} />
                <Grid item md={3} xs={12}>
                    <Card className={classes.card}>
                        <CardContent>
                            <Typography variant="body2">Total Orders</Typography>
                            <Typography variant="body2">{data.total_orders}</Typography>
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>

            <Box mt={3} />
            
        </React.Fragment>
    );
};
