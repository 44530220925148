import React from "react";
import {
  Datagrid,
  EditButton,
  EmailField,
  List,
  ReferenceField,
  ShowButton,
  TextField,
} from "react-admin";
import { NONE } from "../../utils";
import ActivationButton from "../backofficeUsers/ActivationButton";
import ResetPasswordButton from "../backofficeUsers/ResetPasswordButton";
import CustomizedBooleanField from "./CustomizedBooleanField";
import OrganizationUsersListFilter from "./OrganizationUsersListFilter";

export const OrganizationUsersList = (props) => {
  return (
    <List
      {...props}
      title="Organizations"
      bulkActionButtons={false}
      filters={<OrganizationUsersListFilter />}
    >
      <Datagrid rowClick={NONE} size="medium" >
        <TextField source="masked_id" label="ID" />
        <TextField source="created_on" label="Registration Date" />
        <TextField source="name" label="Organization Name" />
        <TextField source="occupations" label="Occupation Code" />
        <TextField source="country_of_registration" label="Place of Registration" />   
        <TextField source="detailed_activity" label="Details of Activity" style={{wordBreak: 'break-word', padding: "0 !important"}}/>
        
        <CustomizedBooleanField
          source="needs_approval_decision"
          label="Needs Decision?"
        />
        <CustomizedBooleanField source="is_approved" label="Is Approved?" />
        <ShowButton />
      </Datagrid>
    </List>
  );
};
