import React from "react";
import {
  Datagrid,
  EditButton,
  List,
  TextField,
  useDataProvider,
  useNotify,
  NumberField
} from "react-admin";
import { NONE, MODULES, ACCESS_LEVELS, hasAccess } from "../../utils";

export const CurrencyRateRulesList = (props) => {
  const notify = useNotify();
  const dataProvider = useDataProvider();

  return (
    <List {...props} title="Currency Rate Rules" bulkActionButtons={false}>
      <Datagrid rowClick={NONE}>  
        <TextField source="order_type" label="Order Type" />
        <TextField source="base_currency" label="Base Currency" />
        <TextField source="quote_currency" label="Quote Currency" />
        <TextField source="rate_limit" label="Rate Limit" />
        <TextField source="increment" label="Increment" />
        <NumberField source="min_rate" label="Min Rate" />
        <NumberField source="max_rate" label="Max Rate" />
        <NumberField source="quote_amount_precision" label="Quote Amount Precision" />
        <NumberField source="precision" label="Precision" />
        {hasAccess(MODULES.platformRates, ACCESS_LEVELS.update) && <EditButton/>}
      </Datagrid>
    </List>
  );
};
