import * as React from 'react';
import { AppBar } from 'react-admin';


const MyAppBar = props => {
    return (
        <AppBar {...props} style={{background:"#2196f3"}}/>
    );
};

export default MyAppBar;