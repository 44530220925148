import PlayArrowIcon from "@material-ui/icons/PlayArrow";
import React from "react";
import {
  Button,
  Datagrid,
  DeleteWithConfirmButton,
  FileField,
  List,
  required,
  ShowButton,
  TextField,
  useDataProvider,
  useNotify,
  useRefresh,
} from "react-admin";
import { NONE } from "../../utils";

export const TestList = (props) => {
  const notify = useNotify();
  const refresh = useRefresh();
  const dataProvider = useDataProvider();

  const runTestDefinition = async (testDefinitionId) => {
    try {
      let res = await dataProvider.postRoute(
        "matching-algorithm-simulation/" + testDefinitionId,
        {}
      );
      notify(res.data.message);
      refresh();
    } catch (error) {
      console.error("Failed to match");
    }
  };

  const RunButton = ({ record }) => {
    return (
      <Button onClick={() => runTestDefinition(record.id)} label="Run">
        <PlayArrowIcon />
      </Button>
    );
  };

  return (
    <List
      {...props}
      title="Test Definitions"
      bulkActionButtons={false}
      //   actions={<></>}
    >
      <Datagrid rowClick={NONE}>
        <TextField source="id" label="ID" validate={required()} />
        <FileField
          source="orders_csv_path"
          label="File"
          title="display_name"
          download={true}
        />
        <RunButton />
        <ShowButton />
        <DeleteWithConfirmButton />
      </Datagrid>
    </List>
  );
};
