import React from "react";
import { Documents, removeFromArray } from "../../utils";
import ProfileDocumentField from "./ProfileDocumentField";

export const ProfileDocumentTab = (props) => {
  const documents = props?.record?.documents;
  var documentsMissed = props?.documentsMissed;
  var profileType = props?.profileType

  if (documents && documentsMissed && profileType)
    return (
      <>
        {documents.map((value) => {
          if (
            value.document_type == Documents.Passport ||
            value.document_type == Documents.NationalID
          ) {
            documentsMissed = removeFromArray(documentsMissed, Documents.Passport);
            documentsMissed = removeFromArray(documentsMissed, Documents.NationalID);
          }
          documentsMissed = removeFromArray(
            documentsMissed,
            value.document_type
          );
          return (
            <>
              <ProfileDocumentField
                source={value.document_path}
                label={"Document Title"}
                title={value.document_type}
                documentDetails={value}
                profileType={profileType}
                addLabel
                {...props}
              />
            </>
          );
        })}
        {documentsMissed.map((value) => {
          return <div style={{ color: "red" }}>{value} Missed !</div>;
        })}
      </>
    );
  return <></>;
};
