import axios from "axios";
import React, { useEffect } from "react";
import { Create, SelectInput, SimpleForm } from "react-admin";
import FileUploaderInput from "../comman-components/FileUploader";

export const CreateANewSupportedCurrency = (props) => {
  const [currencyList, setCurrencyList] = React.useState("");
  const getGlobalCurrencies = async () => {
    let res = await axios.get(
      "https://openexchangerates.org/api/currencies.json"
    );
    var result = [];

    for (var currency in res.data)
      result.push({
        id: `${currency} - ${res.data[currency]}`,
        name: `${currency} - ${res.data[currency]}`,
      });
    setCurrencyList(result);
  };
  useEffect(() => {
    getGlobalCurrencies();
  }, []);

  if (currencyList)
    return (
      <Create title="Create A new Reason" {...props}>
        <SimpleForm redirect="list">
          <SelectInput source="currency" required choices={currencyList} />
          <FileUploaderInput
            name="currency_flag_24px"
            label="Currency flag Image of size 24px x 24px"
            files_accepted="image/png"
          />
          <FileUploaderInput
            name="currency_flag_48px"
            label="Currency flag Image of size 48px x 48px"
            files_accepted="image/png"
          />
        </SimpleForm>
      </Create>
    );
  else return <></>;
};
