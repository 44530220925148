import React from "react";
import {
  ChipField,
  Datagrid,
  EditButton,
  EmailField,
  List,
  ReferenceField,
  TextField,
} from "react-admin";
import { NONE, MODULES, ACCESS_LEVELS, hasAccess } from "../../utils";
import CustomizedBooleanField from "../organizationUsers/CustomizedBooleanField";
import ActivationButton from "./ActivationButton";
import ResetPasswordButton from "./ResetPasswordButton";

export const BackOfficeUsersList = (props) => {
  return (
    <List
      {...props}
      title="BackOffice Users"
      bulkActionButtons={false}
    >
      <Datagrid rowClick={NONE}>
        <TextField source="id" />
        <EmailField source="email" />
        <TextField source="roles" sortable={false} />
        <CustomizedBooleanField source="is_active" />
        <ReferenceField
          label="Deactivated By"
          source="deactivated_by"
          reference="backoffice-users"
          sortable={false}
        >
          <ChipField source="email" />
        </ReferenceField>
        {hasAccess(MODULES.backofficeUsers, ACCESS_LEVELS.update) && <EditButton />}
        {hasAccess(MODULES.backofficeUsers, ACCESS_LEVELS.update) && <ActivationButton />}
        {hasAccess(MODULES.backofficeUsers, ACCESS_LEVELS.update) && <ResetPasswordButton />}
      </Datagrid>
    </List>
  );
};
