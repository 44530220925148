import React, { useState } from "react";
import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import TextField from "@material-ui/core/TextField";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import { LOCAL_STORAGE_KEYS } from "../../utils";
import Snackbar from "@material-ui/core/Snackbar";
import { Formik } from "formik";
import * as yup from "yup";

import { useDataProvider, useNotify, useRedirect } from "react-admin";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    "& > * + *": {
      marginTop: theme.spacing(2),
    },
  },
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

export default function ActivationView(props) {
  const notify = useNotify();
  const [Mismatch, setMismatch] = useState(false);
  const dataProvider = useDataProvider();
  const redirect = useRedirect();
  const classes = useStyles();
  const [verificationError, setVerificationError] = useState({
    error: false,
    message: "",
  });

  const validationSchema = yup.object({
    password: yup
      .string()
      .min(10, "Password should be of minimum 10 characters length")
      .required("Password is required")
      .matches(
        "^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[!@#$%^&*-_]).*$", 
        "At least one upper case, one lower case, one number and one special character"
      )
      .nullable(),
    confirmPassword: yup
      .string()
      .required("Confirm Password is required")
      .test(
        "confirm-password-test",
        "Passwords do not match",
        function (value) {
          return value === this.parent.password;
        }
      )
      .nullable(),
  });


  const handlePasswordReset = async (values) => {
    const data = {
      password: values.password,
    };
    const verificationResult = await dataProvider.putRoute(
      "verify/" +
        props.match.params.id +
        "/" +
        props.match.params.activationcode,
      data
    );
    if (verificationResult.data.success) {
      localStorage.setItem(
        LOCAL_STORAGE_KEYS.token,
        verificationResult.data.token
      );

      redirect("/login");
      notify("Welcome");
      return;
    }
    setVerificationError({
      error: true,
      message: verificationResult.data.message,
    });
  };

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <div className={classes.paper}>
        <Avatar className={classes.avatar}>
          <LockOutlinedIcon />
        </Avatar>

        <Typography component="h1" variant="h5">
          Specify your account password
        </Typography>

        <Formik
          initialValues={{
            password: null,
            confirmPassword: null
          }}
          validationSchema={validationSchema}
          onSubmit={(values) => {
            handlePasswordReset(values)}
          } 
        >
          {({
            handleChange,
            handleBlur,
            handleSubmit,
            values,
            touched,
            errors,
          }) => (
            <>
              <TextField
                margin="normal"
                variant="outlined"
                fullWidth
                label="Password"
                id="password"
                type="password"
                onChange={handleChange("password")}
                onBlur={handleBlur("password")}
                value={values.password}
                name="password"
                error={touched.password && Boolean(errors.password)}
                helperText={touched.password && errors.password}
              />

              <TextField
                margin="normal"
                variant="outlined"
                fullWidth
                id="=password"
                type="password"
                label="Confirm Password"
                onChange={handleChange("confirmPassword")}
                onBlur={handleBlur("confirmPassword")}
                value={values.confirmPassword}
                name="confirmPassword"
                error={touched.confirmPassword && Boolean(errors.confirmPassword)}
                helperText={touched.confirmPassword && errors.confirmPassword}
              />

              <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                //disabled={loading}
                className={classes.submit}
                onClick={handleSubmit}         
              >
                Submit
              </Button>
            </>
          )}
        </Formik>
      </div>
      <Snackbar
        open={verificationError.error}
        autoHideDuration={3000}
        message={verificationError.message}
        onClose={() => setVerificationError({ error: false })}
      />
    </Container>
  );
}
