import { TextField } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import {
  Create,
  NumberInput,
  SelectInput,
  SimpleForm,
  useDataProvider,
} from "react-admin";

const CreateNewRule = (props) => {
  const [baseCurrency,setBaseCurrency] = useState("USD");
  const [quoteCurrency,setQuoteCurrency] = useState("LBP");
  const dataProvider = useDataProvider();
  const [errorFound, setErrorFound] = useState(false);
  const [currencyList, setCurrencyList] = useState("");
  const [rate,setRate] = useState(0);
  const [rateLimit,setRateLimit] = useState(0);
  const [maxRate,setMaxRate] = useState(0);
  const [minRate,setMinRate] = useState(0);
  const [step,setStep] = useState(0);

  const getAllCurrencies = async () => {
    let res = await dataProvider.getRoute("get_all_supported_currencies");
    setCurrencyList(res.data);
  };

  const getLiveRate = async () => {
    const res = await dataProvider.getRoute("liveplatformrate",`baseCurrency=${baseCurrency}&quoteCurrency=${quoteCurrency}`);
    if(res.data.success){
      setRate(res.data.buy_rate);
    }else{
      setRate(0);
    }
    
  }

  useEffect(() => {
    getAllCurrencies();
    getLiveRate()
  }, [baseCurrency,quoteCurrency]);

  const handleCurrencySelectionChange=(e)=>{
    if(e.target.name == "baseCurrency"){
      setBaseCurrency(e.target.value);
    }
    if(e.target.name == "quoteCurrency"){
      setQuoteCurrency(e.target.value);
    }
  }

  const orderTypes = [
    { id: "buy", name: "buy" },
    { id: "sell", name: "sell" },
];


  const validateRuleCreation = (values) => {
    const errors = {};
    if (!values.baseCurrency) {
      setErrorFound(true);
      errors.baseCurrency = ["Enter Base Currency"];
    }
    if (!values.quoteCurrency) {
      setErrorFound(true);
      errors.quoteCurrency = ["Enter Quote Currency"];
    }
    if (!values.increment) {
      setErrorFound(true);
      errors.increment = ["Enter Increment Value"];
    }
    if (!values.rate_limit) {
      setErrorFound(true);
      errors.increment = ["Enter Rate Limit Value"];
    }
    if (values.baseCurrency ==  values.quoteCurrency) {
      setErrorFound(true);
      errors.baseCurrency = ["Base currency must not be equal to the Quote currency"];
      errors.quoteCurrency = ["Quote currency must not be equal to the Base currency"];
    } else {
      setErrorFound(false);
    }
    return errors;
  };

  const changeMaxAndMinRate = (e)=>{
    if(e.target.name == "increment"){
      setStep(e.target.value);
    }
    if(e.target.name == "rate_limit"){

      setRateLimit(e.target.value);
    }
    if(!rateLimit || !step){
      setMaxRate(0);
      setMinRate(0)
      return 
    }
    const iMin = rate * (1-rateLimit)
    var realMin = rate
    while(realMin > iMin){
      realMin -= step;
    }
    setMinRate(realMin)
    const iMax = rate * (1+rateLimit)
    var realMax = rate
    while(realMax < iMax){
      realMax +=step
    }
    setMaxRate(realMax);
  }

  if (currencyList)
    return (
      <Create {...props} title="Create New Rating">
        <SimpleForm redirect="list" validate={validateRuleCreation}>
          <SelectInput
            source="baseCurrency"
            choices={currencyList}
            optionText="name"
            optionValue="ticker"
            required
            onChange={handleCurrencySelectionChange}
          />
          <SelectInput
            source="quoteCurrency"
            choices={currencyList}
            optionText="name"
            optionValue="ticker"
            required
            onChange={handleCurrencySelectionChange}
          />
          <NumberInput source="increment" label="Increment" resettable required />
          <NumberInput source="rate_limit" label="Rate limit (%)" resettable required />
          <NumberInput source="quote_amount_precision" label="Quote Amount Precision" resettable required />
          <NumberInput source="precision" label="Precision" resettable required />
        </SimpleForm>
      </Create>
    );
  else return <></>;
};

export default CreateNewRule;
