import React from "react";
import {
  Edit,
  SelectArrayInput,
  SimpleForm,
  TextInput,
  SaveButton,
  Toolbar,
} from "react-admin";
import { roleChoices } from "./common";

const CustimizeEditToolbar = (props) => (
  <Toolbar {...props}>
    <SaveButton />
  </Toolbar>
);

export const EditUser = (props) => (
  <Edit title="Edit Backoffice User" {...props}>
    <SimpleForm toolbar={<CustimizeEditToolbar />} redirect="list">
      <TextInput source="email" disabled />
      <SelectArrayInput
        source="roles"
        allowEmpty={false}
        resettable
        multiple
        choices={roleChoices}
      />
    </SimpleForm>
  </Edit>
);
