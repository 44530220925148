import * as React from 'react';
import get from 'lodash/get';
import { Link } from '@material-ui/core';
import { useRecordContext } from 'ra-core';

const CustomizedDocumentLink = (props) => {
    const { source, placeholder, ...rest } = props;
    const record = useRecordContext(props);
    const value = get(record, source);
    

    if(value && value[record.document_type])
        return (
            <Link
                href={value[record.document_type]}
                variant="body2"
                target='_blank'
            >
                {placeholder}
            </Link>
        );
    return <></>
};

export default CustomizedDocumentLink;
