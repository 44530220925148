import React from "react";
import {
  Datagrid,
  EmailField,
  List,
  SearchInput,
  ShowButton,
  TextField,
  useDataProvider,
  useNotify,
} from "react-admin";
import { NONE } from "../../utils";
import CustomizedBooleanField from "../organizationUsers/CustomizedBooleanField";

export const EnterpriseUsersList = (props) => {
  const notify = useNotify();
  const dataProvider = useDataProvider();

  const postFilters = [
    <SearchInput source="search" alwaysOn />
  ];

  return (
    <List {...props} title="Business Enterprise" bulkActionButtons={false} filters={postFilters}>
      <Datagrid rowClick={NONE}>
      <TextField source="masked_id" label="ID" />
        <TextField source="created_on" label="Registration Date" />
        <TextField source="name" label="Enterprise Name" />
        <TextField source="first_name" label="First Name" />
        <TextField source="last_name" label="Last Name" />
        <TextField source="date_of_birth" label="Date of Birth" />
        <TextField source="nationality" label="Nationality" />
        <TextField source="residence" label="Residence" />
        <CustomizedBooleanField
          source="is_expired"
          label="Document Expired ?"
        />
        <CustomizedBooleanField
          source="needs_approval_decision"
          label="Needs Decision ?"
        />
        <CustomizedBooleanField source="is_approved" label="Is Approved ?" />
        <ShowButton />
      </Datagrid>
    </List>
  );
};
