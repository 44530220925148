import {SERVER_URL} from "../env";
import {LOCAL_STORAGE_KEYS} from "../utils";
import decodeJwt from 'jwt-decode';

function isPublicRoute() {
    return window.location.hash.startsWith("#/public/verify");
}

const GUEST_PERMISSIONS = {id: "guest", fullName: "Guest", roles: ["Guest"], email: "guest"};

export default {
    // authentication
    login: params => {
        const {username, password} = params;
        const request = new Request(SERVER_URL + '/authenticate', {
            method: 'POST',
            body: JSON.stringify({email: username, password}),
            headers: new Headers({'Content-Type': 'application/json'}),
        });

        return fetch(request)
            .then(response => response.json())
            .then(({success, message, token, redirect}) => {
                if(redirect != null) {
                    window.open(redirect)
                }

                if (!success) {
                    throw new Error(message);
                }
                localStorage.setItem(LOCAL_STORAGE_KEYS.token, token);
            });
    },
    checkError: (error) => {
        const status = error.status;
        if (status === 401 || status === 403) {
            localStorage.removeItem(LOCAL_STORAGE_KEYS.token);
            return Promise.reject();
        }
        // other error code (404, 500, etc): no need to log out
        return Promise.resolve();
    },
    checkAuth: (params) => {
        return localStorage.getItem(LOCAL_STORAGE_KEYS.token) || isPublicRoute()
            ? Promise.resolve()
            : Promise.reject()
    },
    logout: () => {
        localStorage.removeItem(LOCAL_STORAGE_KEYS.token);
        return Promise.resolve('/login');
    },
    getIdentity: () => {
        if(isPublicRoute()) {
            return Promise.resolve(GUEST_PERMISSIONS)
        }
        const token = localStorage.getItem(LOCAL_STORAGE_KEYS.token);
        if (!token) {
            return Promise.reject("You are not logged in");
        }
        try {
            const decodedToken = decodeJwt(token);
            return Promise.resolve({id: decodedToken.email, fullName: decodedToken.email})
        } catch (error) {
            return Promise.reject(error);
        }

    },
    // authorization
    getPermissions: () => {
        if(isPublicRoute()) {
            return Promise.resolve(GUEST_PERMISSIONS)
        }
        const token = localStorage.getItem(LOCAL_STORAGE_KEYS.token);
        if (!token) {
            return Promise.reject("You are not logged in");
        }
        try {
          const decodedToken = decodeJwt(token);
          return Promise.resolve({roles: decodedToken.roles, email: decodedToken.email, access_levels: decodedToken.permissions})
        } catch (error) {
            return Promise.reject(error);
        }

    },

};
