import { Filter, SearchInput } from "react-admin";
import UserSelector from "./UserSelector";

function OrganizationUsersListFilter(props) {
  return (
    <Filter {...props}>
      <SearchInput source={"search"} alwaysOn />
      <UserSelector source={"approvedUsers"} label={"Approved Organizations"} />
      <UserSelector source={"verifiedUsers"} label={"Needs Approval"} />
    </Filter>
  );
}

export default OrganizationUsersListFilter;
