import decodeJwt from 'jwt-decode';

export const LOCAL_STORAGE_KEYS = {
  token: "token",
};

export function NONE() {}

export const ROLES = {
  admin: "Admin",
  fx: "FX",
  tester: "Tester",
  compliance: "Compliance",
  customerSupport: "Customer Support",
};

export const MODULES = {
  backofficeUsers: "Backoffice Users",
  businessEnterprise: "Business Enterprise",
  currencyRateRules: "Currency Rate Rules",
  flows: "Flows",
  individuals: "Individuals",
  killswitchTracking: "Killswitch Tracking",
  matches: "Matches",
  matchmakingSimulation: "Matchmaking Simulation",
  orders: "Orders",
  organizations: "Organizations",
  profileTypeRules: "Profile Type Rules",
  platformRates: "Platform Rates",
  platformUsers: "Platform Users",
  supportedCurrencies: "Supported Currencies",
  supplyDemandThreshold: "Supply Demand Threshold",
  systemSettings: "System Settings"
};

export const ACCESS_LEVELS = {
  read: "read",
  create: "create",
  update: "update",
  delete: "delete"
};

export const hasAccess = (module, access_level) => {
  const token = localStorage.getItem(LOCAL_STORAGE_KEYS.token);
  const decodedToken = decodeJwt(token);
  const access_levels = decodedToken.permissions;
  
  return access_levels.some((value) => {
    return value.module == module && value.access_level.indexOf(access_level) > -1;
  });
};

export const numberWithCommas = (number) => {
  if (number == null) {
    return number;
  }

  var values = number.toString().split(".");
  return (
    values[0].replace(/.(?=(?:.{3})+$)/g, "$&,") +
    (values.length == 2 ? "." + values[1] : "")
  );
};

export const MatchStatus = {
  Approved: "Approved",
  SentToDelivery: "Sent to Delivery",
  FailedToDeliver: "Failed to Deliver",
  Completed: "Completed",
  Dispatched: "Dispatched",
  Pending:"Pending",
  Done:"Done",
  Canceled:"Canceled"
};
    
export const OrderApprovalStatus = {
  Approved: "Approved",
  SOURCE_OF_FUND_PROVIDED: "SOURCE OF FUND PROVIDED",
  DEPOSIT_REQUESTED: "DEPOSIT REQUESTED",
  DEPOSIT_INFO_PROVIDED: "DEPOSIT INFO PROVIDED",
  DECLINED : "DECLINED"
};

export const DocumentStatus = {
  Approved: "Approved",
  Rejected: "Rejected",
  PendingApproval: "Pending Approval",
  ToBeUploaded: "To Be Uploaded",
};

export const OrganizationDocumentsNeeded = [
  "certificate_of_register",
  "general_assembly",
  "board_of_directors",
  "commercial_circular_or_authority_matrix",
  "general_attestation",
  "company_bylaws",
];

export const Documents = {
  Passport: "Passport",
  NationalID: "National ID",
  Delegation: "Delegation",
  RegistrationCertificate: "Registration Certificate",
  IdFrontSide: "ID Front Side",
  IdBackSide: "ID Back Side"
}

export const IndividualDocumentsNeeded = [
  Documents.NationalID,
  Documents.Passport
];

export const EnterpriseDocumentsNeeded = [
  Documents.NationalID,
  Documents.Passport,
  Documents.RegistrationCertificate
];


export const toBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });

export const removeFromArray = (array, valueToRemove) => {
  return array.filter((value) => value != valueToRemove);
};

export const PROFILETYPES = {
  Enterprise:"Business Enterprise",
  Individual:"Individual",
  Organization:"Organization"
}

export const FIELDSTATUS = {
  "APPROVED":"Approved",
  "REJECTED":"Rejected"
}


export const individualFields = [
  "name",
  "country_of_registration",
  "date_of_registration",
  "email",
  "telephone",
  "detailed_activity",
  "expected_annual_volume_IN_OUT_in_USD",
  "financial_situation_additional_information",
  "head_office_address",
  "head_office_country",
  "legal_form",
  "ownership",
  "website"
]
