const theme = {
    overrides: {
       RaSidebar: {
          fixed: {
            position: 'inherit'
          }
        }
    }
 }
 
 export { theme } 