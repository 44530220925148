import * as React from "react";
import {
  Show,
  SimpleShowLayout,
  TextField,
  DateField,
  RichTextField,
  EmailField,
  EditButton,
  Toolbar,
  TestContext,
} from "react-admin";
import PropTypes from "prop-types";
import { BooleanField } from "react-admin";
import RateDecisionActions from "./RateDecisionActions";
import CustomizedBooleanField from "../organizationUsers/CustomizedBooleanField";

export const ShowRate = (props) => (
  <Show {...props} title={"Rate Details"} actions={<RateDecisionActions />}>
    <SimpleShowLayout>
      <TextField source="id" />
      <TextField source="buy_rate" />
      <TextField source="sell_rate" />
      <TextField source="currency_1_name" label="Base Currency" />
      <TextField source="currency_2_name" label="Quote Currency" />
      <TextField source="created_on" />
      <EmailField source="suggested_by_email" label="Suggested By" />
      <EmailField source="last_decision_by_email" label="Last Decision By" />
      <TextField source="last_decision_comment" label="Last Decision Comment" />
    </SimpleShowLayout>
  </Show>
);
