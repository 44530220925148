import React from 'react'
import { Datagrid, List, TextField,EditButton } from "react-admin";
import { NONE, MODULES, ACCESS_LEVELS, hasAccess } from '../../utils';

const SystemSettingsList = ({permissions,...props}) => {
  
    return (
        <List
        {...props}
        title="System Settings"
        bulkActionButtons={false}
      >
        <Datagrid rowClick={NONE}>
          <TextField source="setting_key" label="Key" />
          <TextField source="setting_content" label="Content" />
          {hasAccess(MODULES.systemSettings, ACCESS_LEVELS.update) && <EditButton />}
        </Datagrid>
      </List>
    )
}

export default SystemSettingsList
