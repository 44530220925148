import * as React from "react";
import { cloneElement, useEffect, useState } from "react";
import {
  useListContext,
  TopToolbar,
  Button,
  sanitizeListRestProps,
  useDataProvider,
  useRefresh,
} from "react-admin";
import StopIcon from "@material-ui/icons/Stop";
import PlayArrowIcon from "@material-ui/icons/PlayArrow";
import { DialogConfirmation } from "./DialogConfirmation";
import { MODULES, ACCESS_LEVELS, hasAccess } from '../../utils'


export default function CustomActionButtons(props) {
  const { className, exporter, filters, maxResults, ...rest } = props;
  const {
    resource,
    displayedFilters,
    filterValues,
    showFilter,
  } = useListContext();
  const dataProvider = useDataProvider();
  const refresh = useRefresh();
  const [systemStatus, setSystemStatus] = useState(true);
  const [reload, setReload] = useState(true);
  const [showConfirmation, setShowConfirmation] = useState(false);

  useEffect(() => {
    getSystemStatus();
  }, [reload]);

  const getSystemStatus = async () => {
    let res = await dataProvider.getRoute("get-system-status");
    if (res.data.success) {
      if (res.data.system_status) return setSystemStatus(true);
      return setSystemStatus(false);
    }
  };

  const killSwitch = async (confirmFirst) => {
    if(confirmFirst){
      setShowConfirmation(true);
    }
    else {
      let res = await dataProvider.postRoute("kill-switch");
      if (res.data.success) {
        setReload(!reload);
        refresh();
        setShowConfirmation(false);
      }
    }
  };

  return (
    <React.Fragment>
      {filters &&
       cloneElement(filters, {
         resource,
         showFilter,
         displayedFilters,
         filterValues,
         context: "button",
       })}

      
      {showConfirmation ? (
        <DialogConfirmation
          showConfirmation={showConfirmation}
          setShowConfirmation={setShowConfirmation}
          onSubmit={() => {killSwitch(false)}}
        />
      ) : (
        <></>
      )}
      {hasAccess(MODULES.killswitchTracking, ACCESS_LEVELS.update) && <Button onClick={() => {killSwitch(!systemStatus)}} label= {systemStatus ? "Emergency Kill" : "Resume Orders"}>
                                                                        {systemStatus ? <StopIcon /> : <PlayArrowIcon />}
                                                                      </Button>}
    </React.Fragment>
  );
}
