import GetAppIcon from "@material-ui/icons/GetApp";
import React, { useState } from "react";
import {
  Button,
  useDataProvider,
  useNotify,
  usePermissions,
  useRefresh,
} from "react-admin";
import { SERVER_URL } from "../../env";

const GenerateProfileReport = (props) => {
  const permissions = usePermissions();
  const notify = useNotify();
  const dataProvider = useDataProvider();
  const refresh = useRefresh();

  const handleGeneratePDF = async () => {
    try {
      let res = await dataProvider.postRoute(props.link, {});
      if(res.data.success){
        if(res.data.url_uuid){
          let uuid = res.data.url_uuid;
          let link = `${SERVER_URL}generate-profile-pdf?uuid=${uuid}`;
          handleDownloadPDF(link);
          return;
        }
      }
    } catch (error) {
      console.error(error);
    }
  }

  const handleDownloadPDF = (link) => {
    var a = document.createElement("a");
    a.href = link;
    document.body.appendChild(a);
    a.click();
    a.parentNode.removeChild(a);
  }

  return (
      <Button
        {...props}
        color="primary"
        startIcon={<GetAppIcon />}
        label="Download As PDF"
        onClick={handleGeneratePDF}
      />
  );
};
export default GenerateProfileReport;
