import {
    Edit,
    SimpleForm,
    SaveButton,
    Toolbar,
    TextInput,
    NumberInput
  } from "react-admin";
  
  
  const CustomizeEditToolbar = (props) => (
    <Toolbar {...props}>
      <SaveButton />
    </Toolbar>
  );
  
  export const EditSupplyDemandThreshold = (props) => (
      <Edit title="Update Supply Demand Threshold" {...props}>
          <SimpleForm toolbar={<CustomizeEditToolbar />} redirect="list">
              <NumberInput source="buffer_limit" label="Buffer Limit" resettable required />
              <NumberInput source="tolerance_percentage" label="Tolerance Percentage" resettable required />
              <NumberInput source="tolerance_absolute" label="Tolerance Absolute" resettable required />
          </SimpleForm>
      </Edit>
  )
  