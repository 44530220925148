import CircularProgress from "@material-ui/core/CircularProgress";
import React, { useEffect, useState } from "react";
import {
  Create,
  NumberInput,
  SaveButton,
  SimpleForm,
  TextInput,
  Toolbar,
  usePermissions,
  SelectInput,
  useDataProvider,
} from "react-admin";

import { ROLES } from "../../utils";
import { DialogConfirmation } from "./DialogConfirmation";
import { CustimizeEditToolbar } from "./CustimizeEditToolbar";

const CreateNewRate = (props) => {
  const dataProvider = useDataProvider();
  const [errorFound, setErrorFound] = useState(false);
  const [currencyList, setCurrencyList] = useState("");

  const getAllCurrencies = async () => {
    let res = await dataProvider.getRoute("get_all_supported_currencies");
    setCurrencyList(res.data);
  };

  useEffect(() => {
    getAllCurrencies();
  }, []);

  const validateUserCreation = (values) => {
    const errors = {};
    if (!values.buy_rate) {
      setErrorFound(true);
      errors.buy_rate = ["Enter your buy rate"];
    }
    return errors;
  };

  if (currencyList)
    return (
      <Create {...props} title="Create New Rating">
        <SimpleForm
          validate={validateUserCreation}
          toolbar={
            <CustimizeEditToolbar
              errorFound={errorFound}
              setErrorFound={setErrorFound}
            />
          }
          redirect="/rates"
        >
          <TextInput
            id="suggested_by"
            source="suggested_by"
            label="Suggested By"
            defaultValue={props.permissions.email}
            disabled
          />
          <NumberInput
            id="buy_rate"
            source="buy_rate"
            label="Buy Rate"
            resettable
            required
            helperText="Sell rate will be calculated automatically using the buy rate"
          />
          <SelectInput
            source="Base Currency"
            choices={currencyList}
            optionText="name"
            optionValue="id"
            required
          />
          <SelectInput
            source="Quote Currency"
            choices={currencyList}
            optionText="name"
            optionValue="id"
            required
          />
        </SimpleForm>
      </Create>
    );
  else return <></>;
};

export default CreateNewRate;
