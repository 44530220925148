import React from "react";
import { RadioButtonGroupInput } from "react-admin";

const dateChoices = [
  { id: 'D', label: '1 Day' },
  { id: 'W', label: '1 Week' },
  { id: 'M', label: '1 Month' },
  { id: 'Y', label: 'YTD' },
];

function DashboardDateFilter(props) {
  return (
      <RadioButtonGroupInput
        source="date"
        choices={dateChoices}
        optionText="label"
        optionValue="id"
        row={false}
        defaultValue="D"
      />
  );
}

export default DashboardDateFilter;
