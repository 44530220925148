import { TextField } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import {
    Create,
    NumberInput,
    SelectInput,
    SimpleForm,
    useDataProvider,
} from "react-admin";

const CreateNewProfileRule = (props) => {
    const dataProvider = useDataProvider();
    const [errorFound, setErrorFound] = useState(false);
    const [currencyList, setCurrencyList] = useState("");
    const profileList = [
        {'name': 'Individual'},
        {'name': 'Business Enterprise'}, 
        {'name': 'Organization'}
    ]

    const getAllCurrencies = async () => {
        let res = await dataProvider.getRoute("get_all_supported_currencies");
        setCurrencyList(res.data);
    };

    useEffect(() => {
        getAllCurrencies();
    }, []);

    const validateRuleCreation = (values) => {
        console.log("values: ", values)
        const errors = {};
        if (!values.profile_type) {
            setErrorFound(true);
            errors.profile_type = ["Select Profile Type"];
        }
        if (!values.currency) {
            setErrorFound(true);
            errors.currency = ["Select Currency"];
        }
        if (!values.max_order_amount) {
            setErrorFound(true);
            errors.max_order_amount = ["Enter Maximum Order Amount"];
        }
        if (!values.min_order_amount) {
            setErrorFound(true);
            errors.min_order_amount = ["Enter Minimum Order Amount"];
        }

        return errors;
    };

    if (currencyList)
        return (
        <Create {...props} title="Create New Profile Rule">
            <SimpleForm redirect="list" validate={validateRuleCreation}>
                <SelectInput
                    source="profile_type"
                    choices={profileList}
                    optionText="name"
                    optionValue="name"
                    required
                />
                <SelectInput
                    source="currency"
                    choices={currencyList}
                    optionText="name"
                    optionValue="ticker"
                    required
                />
                <NumberInput source="max_order_amount" label="Maximum Order Amount" resettable required />
                <NumberInput source="min_order_amount" label="Minimum Order Amount" resettable required />
            </SimpleForm>
        </Create>
        );
    else return <></>;
};

export default CreateNewProfileRule;
