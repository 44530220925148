import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Snackbar from "@material-ui/core/Snackbar";

export const DialogConfirmation = (props) => {
  return (
    <Dialog
      open={props.showConfirmation}
      onClose={()=>props.setShowConfirmation(false)}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">{"Are You Sure?"}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          This Rate Will Be Set Live Automatically
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => props.handleSubmitWithRedirect()}
          color="primary"
          autoFocus
        >
          Yes
        </Button>
        <Button
          onClick={() => {
            props.setShowConfirmation(false);
          }}
          color="primary"
        >
          No
        </Button>
      </DialogActions>
    </Dialog>
  );
};
