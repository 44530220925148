import React from "react";
import { Datagrid, List, ShowButton, TextField,NumberField } from "react-admin";
import { NONE, MODULES, ACCESS_LEVELS, hasAccess } from "../../utils";
import CustomizedBooleanField from "../organizationUsers/CustomizedBooleanField";
import CreateAction from "./CreateAction";
import { CustomEditField } from "./CustomField";
import RatesListFilter from "./RatesListFilter";

export const RatingList = (props) => {
  return (
    <List
      {...props}
      title="Platform Rates"
      bulkActionButtons={false}
      actions={hasAccess(MODULES.platformRates, ACCESS_LEVELS.create) ? <CreateAction /> : null}
      sort={{ field: "created_on", order: "DESC" }}
      filters={<RatesListFilter />}
    >
      <Datagrid rowClick={NONE}>
        <NumberField source="buy_rate" label="Buy Rate" />
        <NumberField source="sell_rate" label="Sell Rate" />
        <TextField source="cu1_ticker" label="Base Currency" />
        <TextField source="cu2_ticker" label="Quote Currency" />
        <CustomizedBooleanField source="live" label="Currently Live ?" />
        <CustomizedBooleanField source="approved" label="Approved" />
        <CustomizedBooleanField
          source="requires_decisions"
          label="Requires Decision ?"
        />
        <TextField source="created_on" label="Created On" />
        <ShowButton />
        <CustomEditField />
      </Datagrid>
    </List>
  );
};
