import blue from "@material-ui/core/colors/blue";
import Fab from "@material-ui/core/Fab";
import { withStyles } from "@material-ui/core/styles";
import AttachFileIcon from "@material-ui/icons/AttachFile";
import React from "react";
import { Create, Labeled, Loading, SimpleForm, TextInput, BooleanInput, NumberInput, FormDataConsumer } from "react-admin";
import { Field, useField } from "react-final-form";

const uploaderStyles = {
  input: {
    display: "none",
  },
  button: {
    color: blue[900],
    margin: 10,
  },
};

const FileUploaderInputImp = ({ name, label, classes }) => {
  const fieldContent = useField(name);

  const fieldUploadTracking = useField(name + "_tracking");

  if (
    fieldUploadTracking.input.value != null &&
    fieldUploadTracking.input.value.parsing
  ) {
    return <Loading />;
  }

  const updateFieldValue = (event) => {
    let file = event.target.files[0];
    const fileName = file.name;
    const reader = new FileReader();
    fieldUploadTracking.input.onChange({ parsing: true });
    reader.onloadend = function (e) {
      fieldContent.input.onChange({
        fileName: fileName,
        content: reader.result,
      });
      fieldUploadTracking.input.onChange({ parsed: true });
    };
    reader.readAsDataURL(file);
  };

  return (
    <Labeled label={label}>
      <div>
        <Field name={name}>
          {(props) => (
            <div>
              <input
                accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                className={classes.input}
                id={name + "contained-button-file"}
                multiple
                type="file"
                onChange={updateFieldValue}
              />
              <label htmlFor={name + "contained-button-file"}>
                <Fab component="span" className={classes.button}>
                  <AttachFileIcon />
                </Fab>
              </label>
            </div>
          )}
        </Field>
        {fieldUploadTracking.input.value != null &&
        fieldUploadTracking.input.value.parsed ? (
          <p>{fieldContent.input.value.fileName}</p>
        ) : (
          <></>
        )}
      </div>
    </Labeled>
  );
};

const FileUploaderInput = withStyles(uploaderStyles)(FileUploaderInputImp);

const validater = (values, random) => {
  const errors = {};
  if (!values.display_name) {
    errors.display_name = "Name is required";
  }
  if(random){
    if(!values.number_of_orders){
      errors.number_of_orders = "Number of orders is required";
    }
  }
  else{
    if (!values.orders) {
      errors.orders = "File is required";
    }
  }
  return errors;
};

const CreateTestDefinition = (props) => {
  let [random, setRandom] = React.useState(false);
  return (
    <Create {...props} title="Create Test Case">
      <SimpleForm redirect="/algorithm-simulation" validate={function(v) {validater(v, random);}}>
        <TextInput
          source="display_name"
          label="Test Case Name"
          type="text"
          resettable
        />
        <BooleanInput
          source="is_random"
          defaultValue={false}
          label="Random Input?"
        />
        <FormDataConsumer>
          {({formData, ...rest}) => (
            formData.is_random ?
              <NumberInput
                source="number_of_orders"
                label="Number of Orders"
              /> :
              <FileUploaderInput name="orders" label="Test Orders" />
          )}
        </FormDataConsumer>
      </SimpleForm>
    </Create>
  );
};

export default CreateTestDefinition;
