import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";

export const DialogConfirmation = (props) => {
  return (
    <Dialog
      open={props.showConfirmation}
      onClose={()=>props.setShowConfirmation(false)}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">{"Resume Orders"}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          Are you sure you want to resume?
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
    onClick={() => props.onSubmit()}
          color="primary"
          autoFocus
        >
          Yes
        </Button>
        <Button
          onClick={() => {
            props.setShowConfirmation(false);
          }}
          color="primary"
        >
          No
        </Button>
      </DialogActions>
    </Dialog>
  );
};
