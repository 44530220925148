import React, { useEffect, useState } from "react";
import { SelectInput, useDataProvider } from "react-admin";
import { nodeModuleNameResolver } from "typescript";

function CurrencySelector(props) {
  const dataProvider = useDataProvider();
  const [list, setCurrencyList] = useState([
    { label: "yes", value: 1 },
    { label: "no", value: 0 },
  ]);

  return (
    <SelectInput
      source={props.source}
      choices={list}
      optionText="label"
      optionValue="value"
      style={{ marginTop: 40 }}
      label={props.label}
    />
  );
}

export default CurrencySelector;
