import React from "react";
import { TopToolbar } from "react-admin";
import { Documents, removeFromArray } from "../../utils";
import ProfileApproveButton from "./ProfileApproveButton";
import ProfileDisapproveButton from "./ProfileDisapproveButton";

export default function VerifyProfileActions(props) {
  const profileType = props?.profileType;
  var documentsNeeded = props?.documentsNeeded?.map((value)=>value);
  if (props.data && !props.data.needs_approval_decision) return <></>;

  if (props.data && props.data.documents){
    const documentsUploaded = props.data.documents.map(
      (document) => document["document_type"]
    );

    for (let document of documentsUploaded) {
      if (document == Documents.Passport || document == Documents.NationalID) {
        documentsNeeded = removeFromArray(documentsNeeded, Documents.Passport);
        documentsNeeded = removeFromArray(documentsNeeded, Documents.NationalID);
      } else {
        documentsNeeded = removeFromArray(documentsNeeded, document);
      }
    }
    if (documentsNeeded.length > 0) {
      return <></>;
    } 

    let pendingDoc = props.data.documents.findIndex((document) => document.document_status == "Pending Approval")
    
    if(pendingDoc >= 0)
      return <></>

    let rejectedDoc = props.data.documents.findIndex((document) => document.document_status == "Rejected")

    if(rejectedDoc >= 0)
      return (
        <ProfileDisapproveButton {...props} profileType={profileType} />
      );

    return (
      <>
        <ProfileDisapproveButton {...props} profileType={profileType} />
        <ProfileApproveButton {...props} profileType={profileType} />
      </>
    );
  }
  return <></>
}
