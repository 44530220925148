import {
  Edit,
  SimpleForm,
  SaveButton,
  Toolbar,
  NumberInput
} from "react-admin";


const CustomizeEditToolbar = (props) => (
  <Toolbar {...props}>
    <SaveButton />
  </Toolbar>
);

export const EditProfileTypeRule = (props) => (
    <Edit title="Update Profile Rule" {...props}>
        <SimpleForm toolbar={<CustomizeEditToolbar />} redirect="list">
            <NumberInput source="max_order_amount" label="Maximum Order Amount" resettable required />
            <NumberInput source="min_order_amount" label="Minimum Order Amount" resettable required />
        </SimpleForm>
    </Edit>
)
