import CircularProgress from "@material-ui/core/CircularProgress";
import React, { useEffect, useState } from "react";
import {
  Edit,
  NumberInput,
  SaveButton,
  SimpleForm,
  TextInput,
  Toolbar,
  usePermissions,
} from "react-admin";
import { ROLES } from "../../utils";
import { DialogConfirmation } from "./DialogConfirmation";

export const EditRate = (props) => {
  const [errorFound, setErrorFound] = useState(false);

  const CustimizeEditToolbar = (props) => {
    const permission = usePermissions();
    const [showConfirmation, setShowConfirmation] = useState(false);
    const [loading, setLoading] = useState(true);
    useEffect(() => {
      if (permission.loaded) {
        setLoading(false);
      }
    }, [permission.loaded]);
    if (loading) {
      return <CircularProgress />;
    }
    if (props.invalid) {
      return (
        <Toolbar {...props}>
          <SaveButton label="Save" />
        </Toolbar>
      );
    }

    if (permission.permissions.roles.includes(ROLES.fxManager)) {
      return (
        <Toolbar {...props}>
          <SaveButton
            label="Save"
            handleSubmitWithRedirect={() => {
              setShowConfirmation(true);
              return false;
            }}
          />
          {showConfirmation ? (
            <DialogConfirmation
              showConfirmation={showConfirmation}
              setShowConfirmation={setShowConfirmation}
            />
          ) : (
            <></>
          )}
        </Toolbar>
      );
    } else {
      return (
        <Toolbar {...props}>
          <SaveButton label="Save" />
        </Toolbar>
      );
    }
  };

  const CustomizedTextField = (props) => {
    props.record.suggested_by_email = props.permissions.email;
    return (
      <TextInput source="suggested_by_email" label="Suggested By" disabled />
    );
  };

  const validateUserCreation = (values) => {
    const errors = {};
    if (!values.buy_rate) {
      setErrorFound(true);
      errors.buy_rate = ["Enter your buy rate"];
    }
    if (!values.sell_rate) {
      setErrorFound(true);
      errors.sell_rate = ["Enter your sell rate"];
    }
    if (values.buy_rate < values.sell_rate) {
      setErrorFound(true);
      errors.sell_rate = ["Sell Rate must be less than the buy rate"];
      errors.buy_rate = ["Buy Rate must be higher than the sell rate"];
    }
    if (!values.buy_rate && !values.sell_rate) {
      setErrorFound(true);
    } else {
      setErrorFound(false);
    }
    return errors;
  };

  return (
    <Edit title="Edit Rate" {...props}>
      <SimpleForm
        toolbar={
          <CustimizeEditToolbar
            errorFound={errorFound}
            setErrorFound={setErrorFound}
          />
        }
        validate={validateUserCreation}
        redirect="list"
      >
        <CustomizedTextField {...props} addlabel />
        <TextInput source="currency_1_name" disabled label="Base Currency" />
        <TextInput source="currency_2_name" disabled label="Quote Currency" />
        <NumberInput source="buy_rate" label="Buy Rate" resettable />
        <NumberInput source="sell_rate" label="Sell Rate" resettable />
      </SimpleForm>
    </Edit>
  );
};
