import React from "react";
import { Datagrid, List, TextField, TopToolbar } from "react-admin";
import { NONE } from "../../utils";
import CustomActionButtons from "./CustomActionButtons";

const Toolbar = (props) => {
  return (
    <TopToolbar className={props.className}>
      <CustomActionButtons {...props} />
    </TopToolbar>
  )
}

export const SystemStatusList = (props) => {
  return (
    <List
      {...props}
      title="System Status Log"
      bulkActionButtons={false}
      actions={<Toolbar />}
    >
      <Datagrid rowClick={NONE}>
        <TextField source="stopped_on" label="System Stopped on" />
        <TextField source="activated_on" label="Reactivated on" />
      </Datagrid>
    </List>
  );
};
