import * as React from "react";
import PropTypes from 'prop-types';
import {BooleanField} from 'react-admin';

const CustomizedBooleanField = ({source, record = {}, label}) => {
    return <BooleanField record={{value: record[source] == 1}} source="value" label={label}/>;
};

CustomizedBooleanField.propTypes = {
    label: PropTypes.string,
    record: PropTypes.object,
    source: PropTypes.string.isRequired,
};

export default CustomizedBooleanField;
