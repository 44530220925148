import { TextField } from "@material-ui/core";
import React from "react";
import { Filter } from "react-admin";
import CurrencySelector from "./CurrencySelector";

function RatesListFilter(props) {
  return (
    <Filter {...props}>
      <CurrencySelector source={"baseCurrency"} />
      <CurrencySelector source={"quoteCurrency"} />
    </Filter>
  );
}

export default RatesListFilter;
