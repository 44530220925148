import React from "react";
import {
  Edit,
  SelectArrayInput,
  SimpleForm,
  TextInput,
  SaveButton,
  Toolbar,
  NumberInput,
  SelectInput
} from "react-admin";
import {countries} from '../countries';

const CustimizeEditToolbar = (props) => (
  <Toolbar {...props}>
     <SaveButton />
  </Toolbar>
);

export const OrganizationProfileEdit = (props) => (
  <Edit title="Edit Organization Profile" {...props} >
    <SimpleForm toolbar={<CustimizeEditToolbar />} redirect="list">
      <TextInput source="email" disabled />
      <TextInput source="organization_name" label="Organization Name" resettable />
      <NumberInput source="phone_number" label="Phone Number" resettable />
      <SelectInput
          source="country_of_incorporation"
          label="Country of Incorporation"
          choices={countries}
          optionText="label"
          optionValue="label"
          resettable
        />
        <TextInput source="first_name" label="First Name" resettable />
        <TextInput source="last_name" label="Last Name" resettable />
        <TextInput source="position" label="Position" resettable />
        <TextInput source="organization_address" label="Address" resettable/>
        <TextInput source="city" label="City" resettable />
        <TextInput source="mof_registration" label="MOF Registration" />
    </SimpleForm>
  </Edit>
);

