import * as React from "react";
import { cloneElement, useEffect, useState } from "react";
import {
  Button,
  CreateButton,
  sanitizeListRestProps,
  TopToolbar,
  useDataProvider,
  useListContext,
  useRefresh,
} from "react-admin";
import CustomActionButtons from "../systemStatusLog/CustomActionButtons";

export default function CreateAction(props) {
  const { className, exporter, filters, maxResults, ...rest } = props;
  const {
    currentSort,
    resource,
    displayedFilters,
    filterValues,
    hasCreate,
    basePath,
    selectedIds,
    showFilter,
    total,
  } = useListContext();
  const dataProvider = useDataProvider();
  const refresh = useRefresh();
  const [systemStatus, setSystemStatus] = useState(true);
  const [reload, setReload] = useState(true);

  useEffect(() => {
    getSystemStatus();
  }, [reload]);

  const getSystemStatus = async () => {
    let res = await dataProvider.getRoute("get-system-status");
    if (res.data.success) {
      if (res.data.system_status) return setSystemStatus(true);
      return setSystemStatus(false);
    }
  };


  return (
    <TopToolbar className={className} {...sanitizeListRestProps(rest)}>
      {filters &&
        cloneElement(filters, {
          resource,
          showFilter,
          displayedFilters,
          filterValues,
          context: "button",
        })}
      <CreateButton basePath={basePath} />

      <CustomActionButtons />
    </TopToolbar>
  );
}
