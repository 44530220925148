import { TextField } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import {
  Create,
  NumberInput,
  SelectInput,
  SimpleForm,
  useDataProvider,
} from "react-admin";

const CreateNewSupplyDemandThreshold = (props) => {
  const [baseCurrency,setBaseCurrency] = useState("");
  const [quoteCurrency,setQuoteCurrency] = useState("");
  const dataProvider = useDataProvider();
  const [errorFound, setErrorFound] = useState(false);
  const [currencyList, setCurrencyList] = useState("");

  const getAllCurrencies = async () => {
    let res = await dataProvider.getRoute("get_all_supported_currencies");
    setCurrencyList(res.data);
  };

  useEffect(() => {
    getAllCurrencies();
  }, []);

  const handleBaseCurrencySelectionChange=(e)=>{
    setBaseCurrency(e.target.value);
  }

  const handleQuoteCurrencySelectionChange=(e)=>{
    setQuoteCurrency(e.target.value);
  }


  const validateSupplyDemandThresholdCreation = (values) => {
    const errors = {};
    if (!values.baseCurrency) {
      setErrorFound(true);
      errors.baseCurrency = ["Enter Base Currency"];
    }
    if (!values.quoteCurrency) {
      setErrorFound(true);
      errors.quoteCurrency = ["Enter Quote Currency"];
    }
    if (!values.buffer_limit) {
      setErrorFound(true);
      errors.bufferLimit = ["Enter Buffer Limit Value"];
    }
    if (!values.tolerance_percentage) {
      setErrorFound(true);
      errors.tolerancePercentage = ["Enter Tolerance Percentage Value"];
    }
    if (!values.tolerance_absolute) {
      setErrorFound(true);
      errors.toleranceAbsolute = ["Enter Tolerance Absolute Value"];
    }
    if (values.baseCurrency ==  values.quoteCurrency) {
      setErrorFound(true);
      errors.baseCurrency = ["Base currency must not be equal to the Quote currency"];
      errors.quoteCurrency = ["Quote currency must not be equal to the Base currency"];
    }
    if (values.buffer_limit < values.tolerance_absolute) {
      setErrorFound(true);
      errors.toleranceAbsolute = ["Absolute tolerance must be less then buffer limit"];
    }
    return errors;
  };

  if (currencyList)
    return (
      <Create {...props} title="Create New Supply Demand Threshold">
        <SimpleForm redirect="list" validate={validateSupplyDemandThresholdCreation}>
          <SelectInput
            source="baseCurrency"
            choices={currencyList}
            optionText="name"
            optionValue="ticker"
            required
            onChange={handleBaseCurrencySelectionChange}
          />
          <SelectInput
            source="quoteCurrency"
            choices={currencyList}
            optionText="name"
            optionValue="ticker"
            required
            onChange={handleQuoteCurrencySelectionChange}
          />
          <NumberInput source="buffer_limit" label= {baseCurrency ? "Buffer Limit In " + baseCurrency : "Buffer Limit"} resettable required />
          <NumberInput source="tolerance_percentage" label="Tolerance Percentage (%)" resettable required />
          <NumberInput source="tolerance_absolute" label={baseCurrency ? "Tolerance Absolute In " + baseCurrency : "Tolerance Absolute"} resettable required />
        </SimpleForm>
      </Create>
    );
  else return <></>;
};

export default CreateNewSupplyDemandThreshold;
