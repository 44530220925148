import React, { useEffect, useState } from "react";
import { SelectInput, useDataProvider } from "react-admin";

function CurrencySelector(props) {
  const dataProvider = useDataProvider();
  const [currencyList, setCurrencyList] = useState("");

  const getAllCurrencies = async () => {
    let res = await dataProvider.getRoute("get_all_supported_currencies");
    setCurrencyList(res.data);
  };

  useEffect(() => {
    getAllCurrencies();
  }, []);

  if (currencyList)
    return (
      <SelectInput
        source={props.source}
        choices={currencyList}
        optionText="ticker"
        optionValue="ticker"
        {...props}
        // style={{ marginTop: 40 }}
      />
    );
  return <></>;
}

export default CurrencySelector;
