import React, { useState } from "react";
import {
  Button,
  useDataProvider,
  useNotify,
  useRefresh
} from "react-admin";
import { DocumentStatus } from "../../utils";
import "./organizationcomponents_css/input.css";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import CancelIcon from "@material-ui/icons/Cancel";
import Grid from "@material-ui/core/Grid";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import MUIButton from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";

const OrganizationDocumentField = ({
  source,
  title,
  label,
  documentDetails,
  record,
}) => {

  const dataProvider = useDataProvider();
  const notify = useNotify();
  const refresh = useRefresh();
  const [openRejectionDialog, setOpenRejectionDialog] = useState(false)
  const [rejectionReason, setRejectionReason] = useState(null)

  const dispalyStatusIcon = () => {
    if (documentDetails["document_status"] == DocumentStatus.Approved) {
      return <CheckCircleIcon fontSize="inherit" style={{ color: "green" }} />;
    }
    if (documentDetails["document_status"] == DocumentStatus.Rejected) {
      return <CancelIcon fontSize="inherit" style={{ color: "red" }} />;
    }
  };

  const updateDocumentStatus = async (
    status,
    organizationId,
    documentType,
    rejectionReason
  ) => {
    try {
      let res = await dataProvider.putRoute(
        "update-organization-document-status/" + organizationId,
        {
          status: status,
          document: documentType,
          reason: rejectionReason
        }
      );
      if (!res.data.success) {
        notify(res.data.message, "error");
      } else {
        notify("Status updated");
      }
    } catch (err) {
      notify("Failed to updated status", "error");
      console.error(err);
    }
    refresh();
  };

  const submitRejectionReason = (e) => {
    e.preventDefault();
    updateDocumentStatus(
      DocumentStatus.Rejected,
      documentDetails["organization_id"],
      documentDetails["document_type"],
      rejectionReason
    )
  }

  if (documentDetails)
    return (
      <>
        <Grid
          container
          direction="row"
          justify="flex-start"
          alignItems="center"
          spacing={1}
        >
          <Grid item>
            <div>
              <a
                href={`${source}?${Date.now()}`}
                title={label}
                download={title}
              >
                {title}
              </a>
            </div>
          </Grid>
          {source &&
            documentDetails["document_status"] !=
              DocumentStatus.ToBeUploaded && (
              <>
                <Grid item>{dispalyStatusIcon()}</Grid>
                <Grid item>
                  {documentDetails["document_status"] !=
                    DocumentStatus.Approved && (
                    <Button
                      label="accept"
                      onClick={() =>
                        updateDocumentStatus(
                          DocumentStatus.Approved,
                          documentDetails["organization_id"],
                          documentDetails["document_type"],
                          null
                        )
                      }
                    />
                  )}
                </Grid>
                <Grid item>
                  {documentDetails["document_status"] !=
                    DocumentStatus.Rejected && (
                      <React.Fragment>
                        <Button
                          label="reject"
                          onClick={() => setOpenRejectionDialog(true)}
                        />
                        <Dialog
                          open={openRejectionDialog}
                          onClose={() => setOpenRejectionDialog(false)}
                        >
                          <DialogTitle id="alert-dialog-title">Rejection Reason</DialogTitle>
                          <form onSubmit={submitRejectionReason}>
                            <DialogContent>
                              <TextField
                                fullWidth
                                onChange={(e) => setRejectionReason(e.target.value)}
                                value={rejectionReason}
                                label="Rejection Reason"
                                name="rejection_reason"
                                required
                              />
                            </DialogContent>
                            <DialogActions>
                              <MUIButton
                                onClick={() => setOpenRejectionDialog(false)}
                                color="primary"
                              >
                                Cancel
                              </MUIButton>
                              <MUIButton
                                type="submit"
                                color="primary"
                                autoFocus
                              >
                                Submit
                              </MUIButton>
                            </DialogActions>
                          </form>
                        </Dialog>
                      </React.Fragment>
                  )}
                </Grid>
              </>
            )}
        </Grid>
      </>
    );
  return <></>;
};

export default OrganizationDocumentField;
