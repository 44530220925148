import React from "react";
import {
  Datagrid,
  EditButton,
  List,
  TextField,
  NumberField
} from "react-admin";
import { NONE, MODULES, ACCESS_LEVELS, hasAccess } from "../../utils";
import CustomizedBooleanField from "../comman-components/CustomizedBooleanField";

export const ProfileTypesRulesList = (props) => {
  return (
    <List {...props} title="Profile Types Rules" bulkActionButtons={false}>
      <Datagrid rowClick={NONE} style={{tableLayout: 'fixed', textAlignLast: "center"}}>
        <TextField source="currency" label="Currency" />
        <TextField source="profile_type" label="Profile Type" />
        <NumberField source="max_order_amount" label="Maximum Order Amount" />
        <NumberField source="min_order_amount" label="Minimum Order Amount" />
        {hasAccess(MODULES.profileTypeRules, ACCESS_LEVELS.update) && <EditButton/>}
      </Datagrid>
    </List>
  );
};
