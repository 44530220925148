import React from "react";
import {
  ArrayField,
  Datagrid,
  EmailField, Show,
  Tab,
  TabbedShowLayout,
  TextField, useDataProvider,
  useNotify,
  ShowButton,
  useRefresh, useShowController,
  UrlField,
  TopToolbar
} from "react-admin";
import { IndividualDocumentsNeeded, PROFILETYPES } from "../../utils";
import CustomizedBooleanField from "../organizationUsers/CustomizedBooleanField";
import { ProfileDocumentTab } from "./ProfileDocumentTab";
import VerifyProfileActions from "./VerifyProfileActions";
import { GridShowLayout, RaGrid } from "ra-compact-ui";
import GenerateProfileReport from "../comman-components/GenerateProfileReport";
import CustomizedLink from "../comman-components/CustomizedLink";

export const IndividualUsersShow = (props) => {
  const { record } = useShowController(props);

  const dataProvider = useDataProvider();
  const notify = useNotify();
  const refresh = useRefresh();
  var fieldsStatus = "";
  if (record && "verification_decision_record" in record) {
    const latestVerification = record?.verification_decision_record?.length;
    if (latestVerification != 0) {
      fieldsStatus =
        record.verification_decision_record[latestVerification - 1]
          ?.fields_status;
    }
  }

  const IndividualActions = (props) => {
    return (
      <TopToolbar>
          <VerifyProfileActions
            profileType={PROFILETYPES.Individual}
            documentsNeeded={IndividualDocumentsNeeded}
            {...props}
          />
          {props.data && (
            <GenerateProfileReport
              link={`generate-individual-pdf/${props.data.id}`}
            />
          )}
      </TopToolbar>
    )
  }

  return (
    <Show
      {...props}
      title={"Individual Profile"}
      actions={<IndividualActions />}
    >
      <TabbedShowLayout>
        <Tab label="Summary">
          <GridShowLayout>
            <RaGrid container direction="row" md={12} xs={12} justifyContent="space-between">
              <RaGrid item md={3} xs={12}>
                <TextField source="masked_id" label="Id" />
              </RaGrid>
              <RaGrid item md={3} xs={12}>
                <EmailField source="user_email" label="Email" />
              </RaGrid>
              <RaGrid item md={3} xs={12}>
                <TextField source="created_on" label="Date of Registration" />
              </RaGrid>
              <RaGrid item md={3} xs={12}>
                <TextField source="mobile_phone" label="Phone Number" /> 
              </RaGrid>
            </RaGrid>
          </GridShowLayout>

          <GridShowLayout>
            <RaGrid container direction="row" md={12} xs={12} justifyContent="space-between">
              <RaGrid item md={3} xs={12}>
                <TextField source="gender" label="Gender" />
              </RaGrid>
              <RaGrid item md={3} xs={12}>
                <TextField source="first_name" label="First Name" />
              </RaGrid>
              <RaGrid item md={3} xs={12}>
                <TextField source="father_name" label="Father Name" />  
              </RaGrid>
              <RaGrid item md={3} xs={12}>
                <TextField source="last_name" label="Last Name" />
              </RaGrid>
              
            </RaGrid>
          </GridShowLayout>

          <GridShowLayout>
            <RaGrid container direction="row" md={12} xs={12} justifyContent="space-between">
              <RaGrid item md={3} xs={12}>
                <TextField source="maiden_name" label="Maiden Name" />  
              </RaGrid>
              <RaGrid item md={3} xs={12}>
                <TextField source="nationality" label="Nationality" />
              </RaGrid>
              <RaGrid item md={3} xs={12}>
                <TextField source="residence" label="Residence" />
              </RaGrid>
              <RaGrid item md={3} xs={12}>
                <TextField source="employment_status" label="Employment Status" />
              </RaGrid>
            </RaGrid>
          </GridShowLayout>

          <GridShowLayout>
            <RaGrid container direction="row" md={12} xs={12} justifyContent="space-between">
              <RaGrid item md={3} xs={12}>
                <TextField source="source_of_funds" label="Source of Funds" />
              </RaGrid>
              <RaGrid item md={3} xs={12}>
                <CustomizedBooleanField source="is_approved" label="Approved ?" addLabel />
              </RaGrid>
              <RaGrid item md={3} xs={12}>
                <CustomizedBooleanField source="is_active" label="Activated Account" addLabel />
              </RaGrid>
            </RaGrid>
          </GridShowLayout>   
        </Tab>

        <Tab label="Documents" {...props}>
          <ProfileDocumentTab
            {...props}
            documentsMissed={IndividualDocumentsNeeded}
            profileType={"Individual"}
          />
        </Tab>

        <Tab label="KYC/KYB Cases" {...props}>
          <ArrayField
            source="kyc_cases"
            label={"KYC/KYB Cases"}
          >
            <Datagrid>
              <TextField source="externalid" label="Case Id" />
              <TextField source="type" label="Type" />
              <TextField source="name" label="Name" />
              <TextField source="matches" label="Matches" />
              <TextField source="provider" label="Provider" />
              <CustomizedLink source="link" label="Link" placeholder="External Case Link" />
            </Datagrid>
          </ArrayField>
        </Tab>

        <Tab label="Verification" {...props}>
          <ArrayField
            source="verification_decision_record"
            label={"Decision Record"}
          >
            <Datagrid>
              <TextField source="decision_date" label="Decision Date" />
              <EmailField source="email" label="Decision By" />
              <TextField source="fields_status" label="Rejected Fields" />
              <TextField source="decision_comment" />
              <TextField source="status" label="Status" />
            </Datagrid>
          </ArrayField>
        </Tab>

        <Tab label="Similar Accounts" {...props}>
        <ArrayField
            source="similar_accounts"
            label={"Similar Accounts"}
          >
            <Datagrid>
              <TextField source="masked_id" label="Id" />
              <TextField source="first_name" label="First Name" />
              <TextField source="father_name" label="Father Name"  />
              <TextField source="last_name" label="Last Name"  />
              <TextField source="date_of_birth" label="Date of Birth" />
              <ShowButton />
            </Datagrid>
          </ArrayField>
        </Tab>
      </TabbedShowLayout>
    </Show>
  );
};
