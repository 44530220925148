// in src/App.js
import { createMuiTheme } from "@material-ui/core/styles";
import TreeMenu from "@bb-tech/ra-treemenu";
import AccountBalanceWalletIcon from "@material-ui/icons/AccountBalanceWallet";
import BusinessIcon from "@material-ui/icons/Business";
import PersonIcon from "@material-ui/icons/Person";
import SyncIcon from "@material-ui/icons/Sync";
import CodeIcon from "@material-ui/icons/Code";
import DashboardIcon from '@material-ui/icons/Dashboard';
import SettingsIcon from "@material-ui/icons/Settings";
import React from "react";
import { Admin, Layout, Resource } from "react-admin";
import CreateTestDefinition from "./components/algorithmSimulation/CreateTestDefinition";
import { TestList } from "./components/algorithmSimulation/TestList";
import { BackOfficeUsersList } from "./components/backofficeUsers/BackOfficeUsersList";
import { BackOfficeUserCreate } from "./components/backofficeUsers/CreateNewUser";
import { EditUser } from "./components/backofficeUsers/EditUser";
import { OrdersList } from "./components/p2pOrders/OrdersList";
import { CreateANewSupportedCurrency } from "./components/currencies/CreateANewSupportedCurrency";
import { CurrenciesList } from "./components/currencies/CurrenciesList";
import { MatchesList } from "./components/matches/MatchesList";
import { OrganizationProfileEdit } from "./components/organizationUsers/OrganizationProfileEdit";
import { OrganizationUsersList } from "./components/organizationUsers/OrganizationUsersList";
import { OrganizationUsersShow } from "./components/organizationUsers/OrganizationUsersShow";
import CreateNewRate from "./components/ratingSettings/CreateNewRate";
import { EditRate } from "./components/ratingSettings/EditRate";
import { RatingList } from "./components/ratingSettings/RatingList";
import { ShowRate } from "./components/ratingSettings/ShowRate";
import { SystemStatusList } from "./components/systemStatusLog/SystemStatusList";
import authProvider from "./customize-ra/authProvider";
import customDataProvider from "./customize-ra/dataProvider";
import customRoutes from "./customize-ra/routes";
import { SERVER_URL } from "./env";
import { ROLES, hasAccess, MODULES, ACCESS_LEVELS } from "./utils";
import { TestExecutionShow } from "./components/algorithmSimulation/TestExecutionShow";
import SystemSettingsList from "./components/systemSettings/SystemSettingsList";
import { CreateSystemSetting } from "./components/systemSettings/SystemSettingsCreate";
import { EditSystemSettings } from "./components/systemSettings/SystemSettingsUpdate";
import { CurrencyRateRulesList } from "./components/currencyRateRules/CurrencyRateRulesList";
import CreateNewRule from "./components/currencyRateRules/CurrencyRateRulesCreate";
import CreateNewSupplyDemandThreshold from "./components/supplyDemandThresholds/supplyDemandThresholdsCreate";
import { EditCurrencyRateRule } from "./components/currencyRateRules/CurrencyRateRulesEdit";
import { theme } from "./components/comman-components/CustomRaSiderBart";
import MyAppBar from "./components/comman-components/MyAppBar";
import { IndividualUsersList } from "./components/individualUsers/IndividualUsersList";
import { IndividualUsersShow } from "./components/individualUsers/IndividualUsersShow";
import { EnterpriseUsersList } from "./components/enterpriseUsers/EnterpriseUsersList";
import { EnterpriseUsersShow } from "./components/enterpriseUsers/EnterpriseUsersShow";
import { OrderShow } from "./components/p2pOrders/OrderShow";
import { UsersList } from "./components/users/UsersList";
import { ProfileTypesRulesList } from "./components/profileTypesRules/ProfileTypesRulesList";
import { SupplyDemandThresholdsList } from "./components/supplyDemandThresholds/supplyDemandThresholdsList";
import CreateNewProfileRule from "./components/profileTypesRules/ProfileTypesRulesCreate";
import { EditProfileTypeRule } from "./components/profileTypesRules/ProfileTypesRulesEdit";
import { EditSupplyDemandThreshold } from "./components/supplyDemandThresholds/supplyDemandThresholdsEdit";
import { FlowsList } from "./components/flows/FlowsList";
import { FlowsShow } from "./components/flows/FlowsShow";
import { Dashboard } from "./components/dashboard/Dashboard";

const dataProvider = customDataProvider(SERVER_URL);

const fetchResources = (permissions) => {
  const roles = permissions.roles;
  const visibleResources = [];
  
  visibleResources.push(<Resource name="visible" />);

  if(roles.includes(ROLES.admin) || roles.includes(ROLES.compliance) || roles.includes(ROLES.tester) || roles.includes(ROLES.customerSupport)){
  visibleResources.push(
    <Resource
      name="users section"
      options={{ label: "Users", isMenuParent: true }}
    />
  );
  }
  visibleResources.push(
    <Resource
      name="dashboard_data"
      options={{ label: "Dashboard" }}
      list={Dashboard}
      icon={DashboardIcon}
    />
  );

  if (roles.includes(ROLES.admin) || roles.includes(ROLES.compliance) || roles.includes(ROLES.tester)){
    visibleResources.push(
      <Resource
        name="backoffice-users"
        options={{ label: "Backoffice Users", menuParent: "users section" }}
        create={hasAccess(MODULES.backofficeUsers, ACCESS_LEVELS.create) ? BackOfficeUserCreate : null}
        list={BackOfficeUsersList}
        edit={hasAccess(MODULES.backofficeUsers, ACCESS_LEVELS.update) ? EditUser : null}
        icon={PersonIcon}
      />
    );
  }

  if (roles.includes(ROLES.compliance) || roles.includes(ROLES.customerSupport) || roles.includes(ROLES.tester)){
    visibleResources.push(
      <Resource
        name="users"
        options={{ label: "Platform Users", menuParent: "users section" }}
        // create={BackOfficeUserCreate}
        list={UsersList}
        // edit={EditUser}
        icon={PersonIcon}
      />
    );
  }

  if (roles.includes(ROLES.compliance) || roles.includes(ROLES.customerSupport) || roles.includes(ROLES.tester)){
    visibleResources.push(
      <Resource
        name="organizations"
        options={{ label: "Organizations", menuParent: "users section" }}
        list={OrganizationUsersList}
        show={OrganizationUsersShow}
        edit={hasAccess(MODULES.organizations, ACCESS_LEVELS.update) ? OrganizationProfileEdit : null}
        icon={BusinessIcon}
      />
    );
  }
  if (roles.includes(ROLES.compliance) || roles.includes(ROLES.customerSupport) || roles.includes(ROLES.tester)){
    visibleResources.push(
      <Resource
        name="individuals"
        options={{ label: "Individuals", menuParent: "users section" }}
        list={IndividualUsersList}
        show={IndividualUsersShow}
      />
    );
  }

  if (roles.includes(ROLES.compliance) || roles.includes(ROLES.customerSupport) || roles.includes(ROLES.tester)){
    visibleResources.push(
      <Resource
        name="enterprises"
        options={{ label: "Business Enterprise", menuParent: "users section" }}
        list={EnterpriseUsersList}
        show={EnterpriseUsersShow}
      />
    );
  }

  visibleResources.push(
    <Resource
      name="configuration section"
      options={{ label: "Platform Configuration", isMenuParent: true }}
    />
  );

  visibleResources.push(
    <Resource
      name="rates"
      options={{ label: "Platform Rates", menuParent: "configuration section" }}
      create={hasAccess(MODULES.platformRates, ACCESS_LEVELS.create) ? CreateNewRate : null}
      list={RatingList}
      edit={hasAccess(MODULES.platformRates, ACCESS_LEVELS.update) ? EditRate : null}
      show={ShowRate}
      icon={SyncIcon}
    />
  );

  visibleResources.push(
    <Resource
      name="currency-rate-rules"
      options={{ label: "Currency Rate Rules", menuParent: "configuration section" }}
      create={hasAccess(MODULES.currencyRateRules, ACCESS_LEVELS.create) ? CreateNewRule : null}
      list={CurrencyRateRulesList}
      edit={hasAccess(MODULES.currencyRateRules, ACCESS_LEVELS.update) ? EditCurrencyRateRule : null}
    />
  );

  visibleResources.push(
    <Resource
      name="supply-demand-thresholds"
      options={{ label: "Supply Demand Thresholds", menuParent: "configuration section" }}
      create={hasAccess(MODULES.supplyDemandThreshold, ACCESS_LEVELS.create) ? CreateNewSupplyDemandThreshold : null}
      list={SupplyDemandThresholdsList}
      edit={hasAccess(MODULES.supplyDemandThreshold, ACCESS_LEVELS.update) ? EditSupplyDemandThreshold : null}
    />
  );

  visibleResources.push(
    <Resource
      name="profile-types-rules"
      options={{ label: "Profile Types Rules", menuParent: "configuration section" }}
      create={hasAccess(MODULES.profileTypeRules, ACCESS_LEVELS.create) ? CreateNewProfileRule : null}
      list={ProfileTypesRulesList}
      edit={hasAccess(MODULES.profileTypeRules, ACCESS_LEVELS.update) ? EditProfileTypeRule : null}
    />
  );

  visibleResources.push(
    <Resource
      name="currencies"
      options={{ label: "Supported Currencies", menuParent: "configuration section" }}
      list={CurrenciesList}
      create={hasAccess(MODULES.supportedCurrencies, ACCESS_LEVELS.create) ? CreateANewSupportedCurrency : null}
      icon={AccountBalanceWalletIcon}
    />
  );

  visibleResources.push(
    <Resource
      name="expected-sales-volume"
      options={{ label: "Expected Sales Volume", menuParent: "configuration section" }}
    />
  );

  visibleResources.push(
    <Resource
      name="system-settings"
      options={{ label: "System Settings", menuParent: "configuration section" }}
      icon={SettingsIcon}
      list={SystemSettingsList}
      create={hasAccess(MODULES.systemSettings, ACCESS_LEVELS.create) ? CreateSystemSetting : null}
      edit={hasAccess(MODULES.systemSettings, ACCESS_LEVELS.update) ? EditSystemSettings : null}
    />
  );
  if (roles.includes(ROLES.fx) || roles.includes(ROLES.compliance) || roles.includes(ROLES.customerSupport) || roles.includes(ROLES.tester)){
    visibleResources.push(
      <Resource
        name="p2p section"
        options={{ label: "P2P FX", isMenuParent: true }}
      />
    );
    
    visibleResources.push(
      <Resource
        name="orders"
        options={{ label: "Orders", menuParent: "p2p section" }}
        list={OrdersList}
        show={OrderShow}
      />
    );

    visibleResources.push(
      <Resource
        name="matches"
        options={{ label: "Matches", menuParent: "p2p section" }}
        list={MatchesList}
      />
    );

    visibleResources.push(
      <Resource
        name="flows"
        options={{ label: "Flows", menuParent: "p2p section" }}
        list={FlowsList}
        show={FlowsShow}
      />
    );
    if (roles.includes(ROLES.fx) || roles.includes(ROLES.compliance) || roles.includes(ROLES.tester)){
      visibleResources.push(
        <Resource
          name="system-status-log"
          options={{ label: "Kill Switch Tracking", menuParent: "p2p section" }}
          list={SystemStatusList}
        />
      );
    }
    if (roles.includes(ROLES.fx) || roles.includes(ROLES.tester)){
      visibleResources.push(
        <Resource
          name="algorithm-simulation"
          options={{ label: "Matchmaking Simulation", menuParent: "p2p section" }}
          list={TestList}
          create={hasAccess(MODULES.matchmakingSimulation, ACCESS_LEVELS.create) ? CreateTestDefinition : null}
          icon={CodeIcon}
          show={TestExecutionShow}
        />
      );
    }
  }

  return visibleResources;
};

const App = () => {
  return (
    <Admin
      customRoutes={customRoutes}
      dataProvider={dataProvider}
      authProvider={authProvider}
      layout={(props) => (
        <Layout {...props} menu={TreeMenu} theme={theme} appBar={MyAppBar} />
      )}
    >
      {fetchResources}
    </Admin>
  );
};

export default App;
