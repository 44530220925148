import blue from "@material-ui/core/colors/blue";
import Fab from "@material-ui/core/Fab";
import { withStyles } from "@material-ui/core/styles";
import AttachFileIcon from "@material-ui/icons/AttachFile";
import React from "react";
import { Labeled, Loading } from "react-admin";
import { Field, useField } from "react-final-form";

const uploaderStyles = {
  input: {
    display: "none",
  },
  button: {
    color: blue[900],
    margin: 10,
  },
};

const FileUploaderInputImp = ({
  name,
  label,
  classes = uploaderStyles,
  files_accepted,
}) => {
  const fieldContent = useField(name);

  const fieldUploadTracking = useField(name + "_tracking");

  if (
    fieldUploadTracking.input.value != null &&
    fieldUploadTracking.input.value.parsing
  ) {
    return <Loading />;
  }

  const updateFieldValue = (event) => {
    let file = event.target.files[0];
    const fileName = file.name;
    const reader = new FileReader();
    fieldUploadTracking.input.onChange({ parsing: true });
    reader.onloadend = function (e) {
      fieldContent.input.onChange({
        fileName: fileName,
        content: reader.result,
      });
      fieldUploadTracking.input.onChange({ parsed: true });
    };
    reader.readAsDataURL(file);
  };

  return (
    <Labeled label={label}>
      <div>
        <Field name={name}>
          {(props) => (
            <div>
              <input
                accept={files_accepted}
                className={classes.input}
                id={name + "contained-button-file"}
                multiple
                type="file"
                onChange={updateFieldValue}
              />
              <label htmlFor={name + "contained-button-file"}>
                <Fab component="span" className={classes.button}>
                  <AttachFileIcon />
                </Fab>
              </label>
            </div>
          )}
        </Field>
        {fieldUploadTracking.input.value != null &&
        fieldUploadTracking.input.value.parsed ? (
          <p>{fieldContent.input.value.fileName}</p>
        ) : (
          <></>
        )}
      </div>
    </Labeled>
  );
};

const FileUploaderInput = withStyles(uploaderStyles)(FileUploaderInputImp);

export default FileUploaderInput;
