import React from "react";
import {
  Create,
  SelectArrayInput,
  SimpleForm,
  TextInput,
  email,
} from "react-admin";
import { roleChoices } from "./common";

const validateEmail = email();

export const BackOfficeUserCreate = (props) => {
  return (
    <Create title="Create A new BackOffice User" {...props}>
      <SimpleForm redirect="list">
        <TextInput
          source="email"
          type="email"
          validate={validateEmail}
          resettable
          required
        />
        <SelectArrayInput
          source="role"
          allowEmpty={false}
          resettable
          multiple
          choices={roleChoices}
          required
        />
      </SimpleForm>
    </Create>
  );
};
