import React, { useState } from "react";
import {
  Button,
  TopToolbar,
  useDataProvider,
  useNotify,
  useRefresh,
  Confirm,
  usePermissions
} from "react-admin";
import { DocumentStatus, OrderApprovalStatus } from "../../utils";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContentText from "@material-ui/core/DialogContentText";
import MButton from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Box from "@material-ui/core/Box";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  paper: {
    minWidth: "40%",
  }
}));

export default function VerifyOrder({ data, ...props }) {
  const dataProvider = useDataProvider();
  const notify = useNotify();
  const refresh = useRefresh();
  const classes = useStyles();
  const permissions = usePermissions();

  const [openApproval, setOpenApproval] = useState(false);
  const [openDisapproval, setOpenDisapproval] = useState(false);
  const [rejectionReason, setRejectionReason] = useState(null);
  
  const handleDecision = async (status) => {
    const res = await dataProvider.putRoute(`update-source-of-fund-proof-status/${data["id"]}`, 
      {
        status: status,
        reason: rejectionReason,
        decision_by: permissions.permissions.email
      }
    );

    if (res.data.success) {
      notify(res.data.message);
      refresh();
    } else {
      notify(res.data.message);
    }
  };
  
  const handleApproval = () => {
    setOpenApproval(false);
    handleDecision(DocumentStatus.Approved);
  };
  
  const handleDisapproval = (e) => {
    e.preventDefault();
    setOpenDisapproval(false);
    handleDecision(DocumentStatus.Rejected);
  };

  const handleOpenDisapproval = () => {
    setOpenDisapproval(true);
  }

  const handleCloseDisapproval = () => {
    setRejectionReason(null);
    setOpenDisapproval(false);
  }
  
  if (!data) return <></>;
  if (data["fund_status"]) {
    if(data['canceled']) return <></>
    if (data["fund_status"] == DocumentStatus.PendingApproval)
      return (
        <TopToolbar>
          <Button
            label="Approve"
            variant="text"
            onClick={() => setOpenApproval(true)}
            {...props}
          />
          <Confirm
            isOpen={openApproval}
            title="Approve Source of Fund"
            content={"Are you sure you want to approve the source of funds provided ?"}
            onConfirm={handleApproval}
            onClose={() => setOpenApproval(false)}
          />

          <Button
            label="Disapprove"
            variant="text"
            onClick={handleOpenDisapproval}
            {...props}
          />
          <Dialog
            open={openDisapproval}
            onClose={handleCloseDisapproval}
            classes={{ paper: classes.paper }}
          >
            <DialogTitle>Disapprove Source of Fund</DialogTitle>
              <form onSubmit={handleDisapproval}>
                <DialogContent>  
                  <DialogContentText>Specify Rejection Reason</DialogContentText>
                  <TextField
                    fullWidth
                    onChange={(e) => setRejectionReason(e.target.value)}
                    value={rejectionReason}
                    label="Rejection Reason"
                    name="rejection_reason"
                    required
                  /> 
                  <DialogActions>
                    <MButton type="submit" color="primary" variant="contained" style={{marginTop: "1rem"}}>
                        Submit
                    </MButton> 
                  </DialogActions>
                </DialogContent>
              </form>
          </Dialog>


        </TopToolbar>
      );
  }

  return <></>;
}
