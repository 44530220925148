import React from "react";
import {
  Datagrid,
  EmailField,
  List,
  TextField,
  useDataProvider,
  useNotify,
  CreateButton,
} from "react-admin";
import { NONE, MODULES, ACCESS_LEVELS, hasAccess } from "../../utils";
import CustomizedBooleanField from "../organizationUsers/CustomizedBooleanField";

export const CurrenciesList = (props) => {
  return (
    <List
      {...props}
      title="Matches"
      bulkActionButtons={false}
      actions={hasAccess(MODULES.supportedCurrencies, ACCESS_LEVELS.create) ? <CreateButton /> : null}
    >
      <Datagrid rowClick={NONE}>
        <TextField source="ticker" label="Ticker" />
        <TextField source="name" label="Name" />
      </Datagrid>
    </List>
  );
};
