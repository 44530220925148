import React from "react";

import { Typography, Card, CardContent, Grid, Box } from '@material-ui/core';


export const Users = (props) => {
    const data = props.data;
    const classes = props.classes;
    
    return (
        <React.Fragment>
            <Typography variant="body1">Quick Totals</Typography>
            <Grid container direction="row" md={12} xs={12}>
                <Grid item md={3} xs={12}>
                    <Card className={classes.card}>
                        <CardContent>
                            <Typography variant="body2">Total Individual Accounts</Typography>
                            <Typography variant="body2">{data.total_individuals}</Typography>
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item md={1} xs={12} />
                <Grid item md={3} xs={12}>
                    <Card className={classes.card}>
                        <CardContent>
                            <Typography variant="body2">Total Business Enterprise Accounts</Typography>
                            <Typography variant="body2">{data.total_business_enterprise}</Typography>
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item md={1} xs={12} />
                <Grid item md={3} xs={12}>
                    <Card className={classes.card}>
                        <CardContent>
                            <Typography variant="body2">Total Corporate Accounts</Typography>
                            <Typography variant="body2">{data.total_organizations}</Typography>
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item md={1} xs={12} />
                <Grid item md={3} xs={12}>
                    <Card className={classes.card}>
                        <CardContent>
                            <Typography variant="body2">Total Users</Typography>
                            <Typography variant="body2">{data.total_users}</Typography>
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>

            <Box mt={3} />

            <Typography variant="body1">Users Breakdown</Typography>
            <Grid container direction="row" md={12} xs={12}>
                <Grid item md={3} xs={12}>
                    <Card className={classes.card}>
                        <CardContent>
                            <Typography variant="body2">Approved</Typography>
                            <Typography variant="body2">{data.total_approved}</Typography>
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item md={1} xs={12} />
                <Grid item md={3} xs={12}>
                    <Card className={classes.card}>
                        <CardContent>
                            <Typography variant="body2">Rejected</Typography>
                            <Typography variant="body2">{data.total_disapproved}</Typography>
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item md={1} xs={12} />
                <Grid item md={3} xs={12}>
                    <Card className={classes.card}>
                        <CardContent>
                            <Typography variant="body2">Pending</Typography>
                            <Typography variant="body2">{data.total_pending}</Typography>
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item md={1} xs={12} />
                <Grid item md={3} xs={12}>
                    <Card className={classes.card}>
                        <CardContent>
                            <Typography variant="body2">Total</Typography>
                            <Typography variant="body2">{data.total_users}</Typography>
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>

            <Box mt={2} />
            
        </React.Fragment>
    );
};
