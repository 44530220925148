import React from "react";
import {
  Edit,
  SelectArrayInput,
  SimpleForm,
  TextInput,
  SaveButton,
  Toolbar
} from "react-admin";


const CustimizeEditToolbar = (props) => (
  <Toolbar {...props}>
    <SaveButton />
  </Toolbar>
);

export const EditSystemSettings = (props) => (
  <Edit title="Update Settings" {...props}>
    <SimpleForm toolbar={<CustimizeEditToolbar />} redirect="list">
      <TextInput source="setting_content"  label="Value"/>
    </SimpleForm>
  </Edit>
);
