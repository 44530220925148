import React from "react";
import {
  Datagrid,
  EditButton,
  useListContext,
  EmailField,
  List,
  ReferenceField,
  ShowButton,
  TextField,
} from "react-admin";
import { NONE } from "../../utils";
import CustomizedBooleanField from "../organizationUsers/CustomizedBooleanField";

export const CustomEditField = (props) => {
  return (
    <EditButton
      disabled={props.record.live || props.record.approved ? true : false}
      {...props}
    />
  );
};
