import React from "react";
import {
  Datagrid,
  DateInput,
  Filter,
  List,
  NumberField,
  SelectInput,
  TextField,
  TextInput,
} from "react-admin";
import { NONE } from "../../utils";
import Actions from "./Actions";

const match_statuses = [
  {id: "Pending", name: "Pending"},
  {id: "Done", name: "Done"},
  {id: "Canceled", name: "Canceled"}
]

const MatchesFilters = (props) => {
  return (
    <Filter {...props}>
      <SelectInput label="Status" source="match_status" choices={match_statuses} />
      <TextInput label="Buyer Account Number" source="buyer_account_number" />
      <TextInput label="Seller Account Number" source="seller_account_number" />
      <DateInput label="Created On" source="match_created_on" />
    </Filter>
  )
}

export const MatchesList = (props) => {
  return (
    <List
      {...props}
      title="Matches"
      bulkActionButtons={false}
      actions={<Actions />}
      filters={<MatchesFilters />}
    >
      <Datagrid rowClick={NONE}>
        <TextField
          source="match_created_on"
          label="Created on"
          sortBy="created_on"
        />
        <TextField source="match_status" label="Match Status" />
        <TextField source="buyer_account_number" label="Buyer Account Number" />
        <TextField source="seller_account_number" label="Seller Account Number" />
        <NumberField source="buy_order_id" label="Buy Order ID" />
        <NumberField source="sell_order_id" label="Sell Order ID" />
        <NumberField source="amount_suggested" label="Base Amount" />
        <TextField source="base_currency" label="Base Currency" />
        <NumberField source="quote_amount" label="Quote Amount" />
        <TextField source="quote_currency" label="Quote Currency" />
        <NumberField source="rate_suggested" label="Rate" />
        <TextField source="commission_amount" label="Commission Amount" sortBy="amount_suggested"/>
        <TextField source="commission_percentage" label="Commission %" />
      </Datagrid>
    </List>
  );
};
