import React, { useEffect, useState } from "react";
import {
  List,
  Tab,
  TabbedShowLayout,
  useDataProvider,
  useNotify,useListContext, Loading, Pagination, 
} from "react-admin";
import { Form } from 'react-final-form';

import { Card as MuiCard, CardContent, withStyles, Button, Box } from '@material-ui/core';
import { Matches } from "./Matches";
import DashboardDateFilter from "../comman-components/DashboardDateFilter";
import {DashboardCurrencyFilter} from "../comman-components/DashboardCurrencyFilter";
import { Volume } from "./Volume";
import { Users } from "./Users";
import { Orders } from "./Orders";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  card: {
    backgroundColor: "#B1AFFF", 
    textAlign: "center",
    marginTop: "1rem"
  }
}));

export const Dashboard = (props) => {
  const notify = useNotify();
  const dataProvider = useDataProvider();
  const classes = useStyles();

  const [tab, setTab] = useState("Matches")
  const [date, setDate] = useState('D')
  const [dashboardData, setDashboardData] = useState();
  const [currencyPair, setCurrencyPair] = useState();
  const [currencyPairs, setCurrencyPairs] = useState();
  const defaultFilterValues = {
    date: 'D',
    currencyPair:'USD,LBP'
  }
  
  const getCurrencyPairs = async () => {
    let res = await dataProvider.getRoute('get_currency_pairs', '');
    if (res.data.success){
      setCurrencyPairs(res.data.currency_pairs);
      setCurrencyPair(res.data.currency_pairs[0]['pair']);
    }
  }

  useEffect(() => {
    getCurrencyPairs();
  }, []);
  
  const getMatchesStatistics = async () => {
    try{
      if (currencyPair){
        let res = await dataProvider.getRoute('dashboard_matches', `date=${date}&currencyPair=${currencyPair}`);
        if(res.data.success){
          setDashboardData(res.data)
        }
      }
    } catch (error) {
      console.error(error);
    }
  }

  const getVolumeStatistics = async () => {
    try{
      if (currencyPair) {
        let res = await dataProvider.getRoute('dashboard_volume', `currencyPair=${currencyPair}`);
        if(res.data.success){
          setDashboardData(res.data)
        }
      }
    } catch (error) {
      console.error(error);
    }
  }

  const getUsersStatistics = async () => {
    try{
      if (date) {
        let res = await dataProvider.getRoute('dashboard_users', `date=${date}`);
        if(res.data.success){
          setDashboardData(res.data)
        }
      }
    } catch (error) {
      console.error(error);
    }
  }

  const getOrdersStatistics = async () => {
    try{
      if (date && currencyPair){
        let res = await dataProvider.getRoute('dashboard_orders', `date=${date}&currencyPair=${currencyPair}`);
        if(res.data.success){
          setDashboardData(res.data)
        }
      }
    } catch (error) {
      console.error(error);
    }
  }

  useEffect(() => {
    if(tab == "Matches"){
      getMatchesStatistics();
    }
    if(tab == "Volume"){
      getVolumeStatistics();
    }
    if(tab == "Users"){
      getUsersStatistics();
    }
    if(tab == "Orders"){
      getOrdersStatistics();
    }
  }, [tab, date, currencyPair])

  const Card = withStyles(theme => ({
    root: {
      [theme.breakpoints.up('sm')]: {
        order: -1,
        width: '15em',
        marginRight: '1em',
      },
      [theme.breakpoints.down('sm')]: {
        display: 'none',
      },
    },
  }))(MuiCard);

  const FilterSidebar = ({tab, currencyPair}) => {
    const {
      filterValues,
      setFilters,
    } = useListContext();

    const onSubmit = (values) => {
      if (Object.keys(values).length > 0) {
        setFilters(values);
        setDate(values.date)
        setCurrencyPair(values.currencyPair)
      }
    };

    const resetFilter = () => {
      
      setFilters(defaultFilterValues);
      setDate(defaultFilterValues.date);
      setCurrencyPair(defaultFilterValues.currencyPair);
    };
    
    return(
      <Card>
        <Form onSubmit={onSubmit} initialValues={filterValues}>
          {({ handleSubmit }) => (
            <form onSubmit={handleSubmit}>
              <CardContent>
                {
                  (tab == "Matches" || tab == "Users" || tab == "Orders") &&
                    <DashboardDateFilter />
                }
                {
                  (tab == "Matches" || tab == "Volume" || tab == "Orders") &&
                    <DashboardCurrencyFilter tab={tab} currencyPairs={currencyPairs}/>
                }

                <Box mt={1} />
                
                <Button variant="outlined" color="primary" type="submit">
                  Filter
                </Button>
                <Button variant="outlined" onClick={resetFilter}>
                  Reset
                </Button>
              </CardContent>
            </form>
          )}
        </Form>
      </Card>
    )
  }

  if(dashboardData)
    return (
      <List 
        {...props} 
        title="Dashboard" 
        bulkActionButtons={false} 
        aside={<FilterSidebar tab={tab} />} 
        pagination={<Pagination limit={null} />}
        filterDefaultValues={{ date: date }}
      >
        <TabbedShowLayout syncWithLocation={false}>
          <Tab label="Matches" onClick={() => setTab("Matches")}>
            <Matches data={dashboardData} classes={classes}/>
          </Tab>
          <Tab label="Orders" onClick={() => setTab("Orders")}>
            <Orders data={dashboardData} classes={classes}/>
          </Tab>
          <Tab label="Volume" onClick={() => setTab("Volume")}>
            <Volume data={dashboardData} classes={classes}/>
          </Tab>
          <Tab label="Users" onClick={() => setTab("Users")}>
            <Users data={dashboardData} classes={classes}/>
          </Tab>
        </TabbedShowLayout>
      </List>
    );
  return <Loading />
};
