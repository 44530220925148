import React from "react";
import {
  Datagrid,
  List,
  TextField,
  NumberField,
  EditButton
} from "react-admin";
import { NONE, MODULES, ACCESS_LEVELS, hasAccess } from "../../utils";

export const SupplyDemandThresholdsList = (props) => {
  return (
    <List {...props} title="Supply Demand Thresholds" bulkActionButtons={false}>
      <Datagrid rowClick={NONE}>
        <TextField source="base_currency" label="Base Currency" />
        <TextField source="quote_currency" label="Quote Currency" />
        <NumberField source="buffer_limit" label="Buffer Limit" />
        <NumberField source="tolerance_percentage" label="Tolerance Percentage" />
        <NumberField source="tolerance_absolute" label="Tolerance Absolute" />
        {hasAccess(MODULES.supplyDemandThreshold, ACCESS_LEVELS.update) && <EditButton/>}
      </Datagrid>
    </List>
  );
};
