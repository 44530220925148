import React, { cloneElement, useState } from "react";
import {
  Button,
  Datagrid,
  EmailField,
  List,
  NumberField,
  TextField,
  TopToolbar,
  useDataProvider,
  useListContext,
  useNotify,
  useRefresh,
  Confirm,
  ShowButton,
} from "react-admin";
import { NONE, MODULES, ACCESS_LEVELS, hasAccess } from "../../utils";
import CustomizedBooleanField from "../comman-components/CustomizedBooleanField";
import MatchListFilter from "../matches/MatchListFilter";

export const OrdersList = (props) => {
  const notify = useNotify();
  const dataProvider = useDataProvider();
  const refresh = useRefresh();
  const [open, setOpen] = useState(false);
  const [openAlert, setOpenAlert] = useState(false);

  const handleOpenConfirmDialog = () => {
    setOpen(true);
  };
  const handleCancelConfirmDialog = () => {
    setOpen(false);
  };

  const handleOpenAlertConfirmDialog = () => {
    setOpenAlert(true);
  };
  const handleCancelAlertConfirmDialog = () => {
    setOpenAlert(false);
  };

  const handleMatchButton = async () => {
    setOpen(false);
    try {
      let res = await dataProvider.postRoute("run-matching-algorithm", {});
      notify(res.data.message);
      refresh();
    } catch (error) {
      console.error("Failed to match");
    }
  };

  const runNewPlatformRateAlgorithm = async () =>{
    setOpenAlert(false);
    try{
      let res = await dataProvider.postRoute("create-new-platform-rate-using-matches", {is_manual: true});
      notify(res.data.message);
      refresh();
    } catch (error) {
      console.error("Failed to update platform rate");
    }
  }

  const MatchingButton = (props) => {
    const { className, exporter, filters, maxResults, data, ...rest } = props;
    const {
      currentSort,
      resource,
      displayedFilters,
      filterValues,
      hasCreate,
      basePath,
      selectedIds,
      showFilter,
      total,
    } = useListContext();
    return (
      <TopToolbar>
        {filters &&
          cloneElement(filters, {
            resource,
            showFilter,
            displayedFilters,
            filterValues,
            context: "button",
          })}
        {hasAccess(MODULES.matchmakingSimulation, ACCESS_LEVELS.create) &&
        <Button
          variant="text"
          color="primary"
          onClick={handleOpenConfirmDialog}
          label="Run Matching Algorithm"
          // {...props}
        />
        }
        {hasAccess(MODULES.platformRates, ACCESS_LEVELS.create) &&
        <Button
        variant="text"
        color="primary"
        onClick={handleOpenAlertConfirmDialog}
        label="Run Platform Rate Algorithm"
        />
        }
        <Confirm
          isOpen={open}
          title="Matching Alogirthm"
          content="Are you sure you want to run the matching alogrithm now?"
          onConfirm={handleMatchButton}
          onClose={handleCancelConfirmDialog}
        />
        <Confirm
          isOpen={openAlert}
          title="New Platform Rate"
          content="Are you sure you want to run the new platform rate alogrithm now?"
          onConfirm={runNewPlatformRateAlgorithm}
          onClose={handleCancelAlertConfirmDialog}
        />
      </TopToolbar>
    );
  };

  const CancelOrderButton = ({ record }) => {
    const { id, order_status } = record;

    const cancelOrder = async (id) => {
      try {
        let res = await dataProvider.putRoute("cancel-order", { "id": id });
        notify(res.data.message);
        refresh();
      } catch (error) {
        console.error(error);
      }
    };

    if ((!record.canceled && (record.matching_status != "COMPLETED")) && record.approval_status != "DECLINED")
      return (
        <Button
          label="Cancel Order"
          variant="outlined"
          onClick={() => cancelOrder(record.masked_id)}
        />
      );
    return <></>
  };

  return (
    <List
      {...props}
      title="P2P Orders"
      bulkActionButtons={false}
      actions={<MatchingButton />}
      filters={<MatchListFilter />}
    >
      <Datagrid rowClick={NONE}>
        <TextField source="id" label="id" />
        <TextField source="created_on" label="Created on" />
        <TextField source="account_number" label="Account Number" />
        <NumberField source="amount" label="Amount" />
        <TextField source="order_type" label="Order Type" />
        <TextField source="cur_1_name" label="Base Currency" />
        <TextField source="cur_2_name" label="Quote Currency" />
        <CustomizedBooleanField source="use_platform_rate" label="Using Platform Rate ?" />
        <NumberField source="rate" label="Rate" />
        <EmailField source="email" label="User Email" />
        <TextField source="approval_status" label="Approval Status" />
        <CustomizedBooleanField source="canceled" label="Is Canceled ?" />
        <CustomizedBooleanField source="is_source_of_fund_provided" label="Source of Fund Provided ?" />
        <ShowButton />
        {hasAccess(MODULES.orders, ACCESS_LEVELS.update) && <CancelOrderButton />}
      </Datagrid>
    </List>
  );
}
