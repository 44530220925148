import Button from "@material-ui/core/Button";
import React from "react";
import { useDataProvider, useNotify, useRefresh } from "react-admin";

const ResetPasswordButton = (props) => {
  const { record } = props;
  const notify = useNotify();
  const dataProvider = useDataProvider();
  const refresh = useRefresh();

  const sendResetMail = async () => {
    try {
      await dataProvider.putRoute("resetpassword/" + record.email);
      notify("Email Sent To Reset Password");
    } catch (err) {
      notify("Failed", "error");
      console.error(err);
    }
    refresh();
  };

  return (
    <Button
      variant="text"
      onClick={sendResetMail}
      color="primary"
      disabled={record.reset_password == 1 ? true : false}
    >
      Reset Password
    </Button>
  );
};
export default ResetPasswordButton;
