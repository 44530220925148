import { SelectInput } from "react-admin";

export const DashboardCurrencyFilter = (props) => {
  let currencyPairs = props.currencyPairs
  return (
    <SelectInput
      source="currencyPair"
      choices={currencyPairs}
      optionValue="pair"
    />
  );
}

